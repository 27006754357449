import { Diversity } from "./constants/diversity";
import { MOODS } from "./constants/moods";

export const SessionEvents = {
  SUGGESTION_REQUEST: "suggestion_request",
  SUGGESTION_SELECTED: "suggestion_selection",
  MODE_SELECTED: "mode_change",
  OPENED_LYRICS: "opened_lyrics",
  CLOSED_LYRICS: "closed_lyrics",
  HID_ASSISTANT: "hid_assistant",
  SHOWED_ASSISTANT: "showed_assistant",
  CLICKED_LOCKED_SUGGESTION: "clicked_locked_suggestion",
  CLICKED_LOCKED_LOAD_MORE: "clicked_locked_load_more",
  CLICKED_LOCKED_METRIC: "clicked_locked_metric",
  RELOADED_SUGGESTIONS: "reloaded_suggestions",
  CLICKED_UPGRADE: "clicked_upgrade",
  CHANGED_SETTINGS: "changed_settings",
  CHANGED_SONG_MOOD: "changed_song_mood",
} as const;

export const Theme = {
  DARK: "dark",
  LIGHT: "light",
};

const Constants = {
  DEFAULT_SONG_TITLE: "My Song Title",
  THROTTLE_DELAY: 1000,
  SONGS_LIMIT_REDIRECTION_URL: "https://studio.moises.ai/pricing/",
  ONBOARDING_DELAY: 3000,

  // Events
  CREATED_INITIAL_SONG_EVENT: "created_initial_song",
  UPDATED_SONG_EVENT: "updated_song",
  EDITOR_FOCUS: "editor_focus",
  ANALYTICS_EVENT: "analytics",
  GLOBAL_ERROR_EVENT: "global_error",
  INFERENCE_API_REQUEST_EVENT: "inference_api_request",

  // Environments
  ENV_DEVELOPMENT: "development",
  ENV_STAGE: "stage",
  ENV_PRODUCTION: "production",

  // Suggestions
  MAX_CONTEXT_LINES: 50,
  MAX_WORDS: 15,
  MAX_DEFINITIONS: 3,
  TYPE_WORDS: "words",
  TYPE_LINES: "lines",
  TYPE_DEFINITIONS: "definitions",
  TYPE_SYNONYMS: "synonyms",
  TYPE_ANTONYMS: "antonyms",
  DEFAULT_GENRE: "General",
  DEFAULT_DIVERSITY: Diversity.MEDIUM,
  DEFAULT_FILTER_PROFANITY: true,
  DEFAULT_MOOD: MOODS.neutral.value,
  DEFAULT_THEME: "dark",
  DELAY_REFRESH_ON_EDIT: 500,
  DELAY_REFRESH_ON_WORD_SELECTION: 500,
  DELAY_REFRESH: 100,
  DELAY_MENU: 500,
  SUGGESTION_MAX_LENGTH: 60,

  // Time
  MINUTE: 60_000,

  // Storage
  KB: 2 ** 10,

  // General
  SNACKBAR_DURATION: 5_000,

  // Analytics
  NO_USER: "no_user",
  PAGE_EXIT: "page_exit",
  PACKAGE_SIZE_EXCEEDED: "package_size_exceeded",
  USER_INACTIVITY: "user_inactivity",
  CONTEXT_CHANGED: "context_changed",

  // Editor
  PERSISTENCE_DELAY: 1_500,

  // Language
  EMPTY_ARRAY: [],

  // Auto complete
  AUTO_COMPLETE_DELAY: 2000,

  //Storage key
  STORAGE_KEY: "session_data_v8",

  //Lyrics key
  LYRICS_KEY: "session_lyrics_ID",
};

export const DEFAULT_SONG_OPTIONS = {
  diversity: Constants.DEFAULT_DIVERSITY,
  genre: Constants.DEFAULT_GENRE,
  filterProfanity: Constants.DEFAULT_FILTER_PROFANITY,
};

export default Constants;
