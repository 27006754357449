import { FetchApiResult } from "..";
import { GraphQLHooks } from "../hooks/graphql-hooks";
import { Status } from "../types/common";

export function extractStatus(
  result: FetchApiResult | GraphQLHooks.QueryResult<unknown>,
  { idleAs = "idle" }: { idleAs?: Status } = {}
): Status {
  if (!result.didOnce) {
    return idleAs;
  }

  if (result.loading) {
    return "loading";
  }

  return result.error ? "failed" : "succeeded";
}
