import { ErrorResponse } from "@apollo/client/link/error";
import { dispatchError } from "../../utils/error-handling-utils";

export function handleNotAuthorized(response: ErrorResponse) {
  const options = response.operation.getContext()?.options;
  const isNotAuthorized = response.graphQLErrors?.some(
    (error) => error.extensions["code"] === "UNAUTHORIZED"
  );

  if (!options?.supressNotAuthorizedAlert && isNotAuthorized) {
    dispatchError(
      "Access error",
      "We couldn't identify you. Please, make sure you are logged in and reload the page.",
      { sticky: true }
    );
  }
}

export function handleClientOutdated(response: ErrorResponse) {
  const isClientOutdated = response.graphQLErrors?.some(
    (error) => error.extensions["code"] === "CLIENT_OUTDATED"
  );

  if (isClientOutdated) {
    dispatchError(
      "There is a new version available",
      "We have made a critial update on the app. Please, refresh the page to use the latest version.",
      { sticky: true }
    );
  }
}

export function handleFailedToFetch(response: ErrorResponse) {
  if (response.networkError?.message === "Failed to fetch") {
    dispatchError(
      "Experiencing network issues",
      "We couldn't properly connect to our servers. Check your connection and try again."
    );
  }
}
