import { useState } from "react";
import { useEvent } from "react-use";
import Constants from "../constants";
import { CustomSnackbar } from "./CustomSnackbar";

type State = {
  visible: boolean;
  title?: string;
  description?: string;
  sticky?: boolean;
  needsRefresh?: boolean;
};

export function ErrorHandler() {
  const [state, setState] = useState<State>({ visible: false });

  useEvent(Constants.GLOBAL_ERROR_EVENT, (event) =>
    setState({ visible: true, ...event.detail })
  );

  function handleClose() {
    setState((state) => ({ ...state, visible: false }));
  }

  return (
    <CustomSnackbar
      title={state.title || ""}
      description={state.description || ""}
      variant="error"
      open={state.visible}
      onClose={handleClose}
      sticky={state.sticky}
    />
  );
}
