import { v4 } from "uuid";

const UUID_CHARS = "0123456789abcdef";
const UUID_FORMAT = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";

/**
 * Generates an (unsafe) UUID.
 * Based on https://stackoverflow.com/a/21963136/2973441.
 * @returns {string}
 */
function unsafeUuid() {
  let u = "",
    i = 0,
    rb = (Math.random() * 0xffffffff) | 0;

  while (i++ < 36) {
    const c = UUID_FORMAT[i - 1],
      r = rb & 0xf,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    u += c == "-" || c == "4" ? c : UUID_CHARS[v];
    rb = i % 8 == 0 ? (Math.random() * 0xffffffff) | 0 : rb >> 4;
  }

  return u;
}

/**
 * Generates an UUID.
 * @returns {string}
 */
export function uuid() {
  try {
    return v4();
  } catch (error) {
    // getRandomValues() may not be available in some browsers causing errors on the call above,
    // so fallback
    return unsafeUuid();
  }
}
