import { isNil } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import createPersistedState from "use-persisted-state";
import { Theme } from "../constants";
import { isClientSide } from "../utils/next-utils";
import * as Sentry from "@sentry/nextjs";
import { useEffectOnce } from "react-use";
import { useFetchGenres } from "../api/inference/hooks";
import { useInDemo } from "../hooks/use-in-demo";
import { Settings } from "..";
import { ParentProps } from "../types/props";

type SettingsStateAction = (
  valueOrFn: Settings | ((settings: Settings) => Settings)
) => void;

export type SettingsContextValue = readonly [
  Settings,
  SettingsStateAction,
  {
    genres: Array<string>;
  }
];

export const LOCAL_STORAGE_KEY = "settings";

export const DEFAULT_SETTINGS = { darkmode: true, autocomplete: true };

export const DEFAULT_SETTINGS_CONTEXT_VALUE = [
  DEFAULT_SETTINGS,
  () => undefined,
  { genres: [] as string[] },
] as const;

export const SettingsContext = createContext<SettingsContextValue>(
  DEFAULT_SETTINGS_CONTEXT_VALUE
);

const useSettingsState = createPersistedState<Settings>(LOCAL_STORAGE_KEY);

export function useSettingsContext() {
  return useContext(SettingsContext);
}

function checkLocalStorageItem(key: string) {
  const value = localStorage.getItem(key);

  if (isNil(value)) {
    return true;
  }

  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
}

function useLocalStorageValidation(key: string) {
  const safeCheck = useRef(false);

  if (isClientSide() && !safeCheck.current) {
    safeCheck.current = true;

    if (!checkLocalStorageItem(key)) {
      Sentry.captureMessage("Invalid settings", {
        level: "error",
        extra: { key: LOCAL_STORAGE_KEY, value: localStorage.getItem(key) },
      });

      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }
}

export function SettingsContextProvider({ children }: ParentProps) {
  useLocalStorageValidation(LOCAL_STORAGE_KEY);

  const [storage, setStorage] = useSettingsState(DEFAULT_SETTINGS);
  const settings = useMemo(
    () => ({ ...DEFAULT_SETTINGS, ...storage }),
    [storage]
  );
  const inDemo = useInDemo();
  const [genresState, fetchGenres] = useFetchGenres({ demo: inDemo });

  const updateSettings: SettingsStateAction = useCallback(
    (valueOrFn) => {
      if (typeof valueOrFn === "function") {
        const fn = valueOrFn;
        setStorage(fn);
      } else {
        const value = valueOrFn;
        setStorage((storage) => ({ ...storage, ...value }));
      }
    },
    [setStorage]
  );

  const value = useMemo(
    () => [settings, updateSettings, { genres: genresState.value }] as const,
    [settings, updateSettings, genresState.value]
  );

  useEffectOnce(() => {
    fetchGenres();
  });

  useEffect(() => {
    document.documentElement.setAttribute(
      "data-theme",
      settings?.darkmode ? Theme.DARK : Theme.LIGHT
    );
  }, [settings]);

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}
