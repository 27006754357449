import { Close as CloseIcon } from "@mui/icons-material";
import { IconButton, Paper, Snackbar } from "@mui/material";
import React, { forwardRef, ReactNode } from "react";
import Constants from "../constants";
import { join } from "../utils/next-utils";
import { CustomButton } from "./custom-button";
import styles from "./CustomSnackbar.module.scss";

type CustomSnackbarContentProps = {
  className?: string;
  title: string;
  description: string;
  buttonText?: string;
  onButtonClick?: () => void;
  icon?: ReactNode;
  onClose: () => void;
};

type CustomSnackbarProps = CustomSnackbarContentProps & {
  open: boolean;
  variant?: Variant;
  sticky?: boolean;
};

const VARIANT_MAP = {
  info: styles.info,
  error: styles.error,
};

type Variant = keyof typeof VARIANT_MAP;

const CustomSnackbarContent = forwardRef<
  HTMLDivElement,
  CustomSnackbarContentProps
>(function CustomSnackbarContent(
  { className, title, description, buttonText, onButtonClick, icon, onClose },
  ref
) {
  return (
    <Paper ref={ref} className={join(styles.root, className)}>
      {icon && <div className={styles.icon}>{icon}</div>}

      <div className={styles.text}>
        <h5>{title}</h5>

        <p>{description}</p>

        {buttonText && (
          <div className={styles.extraButton}>
            <CustomButton
              variant="outlined"
              color="error"
              onClick={onButtonClick}
            >
              {buttonText}
            </CustomButton>
          </div>
        )}
      </div>

      <div className={styles.close}>
        <IconButton size="medium" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
    </Paper>
  );
});

export function CustomSnackbar({
  className,
  title,
  description,
  buttonText,
  onButtonClick,
  icon,
  variant = "info",
  open,
  sticky,
  onClose,
}: CustomSnackbarProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      onClose={onClose}
      autoHideDuration={sticky ? null : Constants.SNACKBAR_DURATION}
    >
      <CustomSnackbarContent
        className={join(className, VARIANT_MAP[variant])}
        title={title}
        description={description}
        buttonText={buttonText}
        onButtonClick={onButtonClick}
        icon={icon}
        onClose={onClose}
      />
    </Snackbar>
  );
}
