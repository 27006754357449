import { StartUpTasks } from "../../constants/start-up-tasks";
import { Analytics } from "../../lib/analytics";
import {
  checkSessionActions,
  transformAnalyticsActions,
  transformAnalyticsEvents,
} from "../../utils/analytics-utils";
import { ActionType } from "./actionTypes";
import { AnalyticsData } from "./types";

export const flush =
  (dispatch: AnalyticsData.Dispatch) =>
  (
    sessionId: string,
    reason: string,
    analytics: AnalyticsData.State,
    task?: StartUpTasks.Any,
    experimentTag?: string
  ) => {
    const hasActions = checkSessionActions(analytics?.actions);
    const hasEvents = analytics?.events.length > 0;

    if (hasActions) {
      const sessionActions = transformAnalyticsActions(
        sessionId!,
        analytics.actions!,
        reason,
        task,
        experimentTag
      );
      Analytics.emitSessionActions(sessionActions);
    }

    if (hasEvents) {
      const sessionEvents = transformAnalyticsEvents(
        sessionId!,
        analytics.events,
        task,
        experimentTag
      );
      Analytics.emitSessionEvents(sessionEvents);
    }

    dispatch({
      type: ActionType.FLUSH,
      payload: {
        reason,
        eventsLength: analytics.events.length,
        emittedActions: hasActions,
      },
    });
  };
