import { combineReducers } from "redux";
import { autoReducer } from "./auto-reducer";
import { rhymesReducer } from "./rhymes-reducer";
import { thesaurusReducer } from "./thesaurus-reducer";

export const reducer = combineReducers({
  auto: autoReducer,
  rhymes: rhymesReducer,
  thesaurus: thesaurusReducer,
});
