import { roundFixed } from "./math-utils";

/**
 * @param {number | string | Date} left
 * @param {number | string | Date} right
 * @returns {number}
 */
export function calculateDurationInSeconds(a, b) {
  const timeDiff = new Date(b).getTime() - new Date(a).getTime();
  return roundFixed(timeDiff / 1_000);
}
