import Constants from "../../../constants";
import { Mode } from "../../../types/mode";
import { createAnalyticsActions } from "../../../utils/analytics-utils";
import { pushUniqueSorted } from "../../../utils/array-utils";
import { ActionType } from "../actionTypes";
import {
  buildApiRequestActionsKey,
  buildEmptyResponseActionsKey,
  buildReducer,
  buildSuggestionSelectionActionsKey,
  getSharingLabel,
  resolveEmptyResponse,
} from "../helpers";
import { AnalyticsData } from "../types";

type State = AnalyticsData.State["actions"];

const reduce = buildReducer<NonNullable<State>, State>({
  [ActionType.SELECTED_SUGGESTION]: (state, payload) => {
    const { mode, type, text } = payload.suggestion!;
    const key = buildSuggestionSelectionActionsKey(
      mode,
      type,
      payload.selectionType!
    );

    return {
      ...state,
      [key]: state[key] + 1,
      chars_added_by_model: state.chars_added_by_model + text.length,
    };
  },

  [ActionType.CHANGED_MODE]: (state, payload) => {
    return payload.current === Mode.THESAURUS
      ? {
          ...state,
          clicked_in_thesaurus: true,
        }
      : state;
  },

  [ActionType.REQUESTED_SUGGESTIONS]: (state, payload) => {
    const requestsKey = buildApiRequestActionsKey(payload.mode!);
    const emptyResponseKey = buildEmptyResponseActionsKey(payload.mode!);

    return {
      ...state,
      [requestsKey]: state[requestsKey] + 1,
      [emptyResponseKey]: resolveEmptyResponse(
        state[emptyResponseKey],
        payload
      ),
    };
  },

  [ActionType.USER_TYPED]: (state, payload) => {
    return {
      ...state,
      chars_added_by_user: state.chars_added_by_user + payload.length!,
    };
  },

  [ActionType.CHANGED_SETTINGS]: (state, payload) => {
    const { darkmode, filterProfanity, genre, diversity } = payload.settings!;
    return {
      ...state,
      chosen_genres: pushUniqueSorted(state.chosen_genres, genre),
      chosen_creativity: pushUniqueSorted(state.chosen_creativity, diversity),
      dark_mode: darkmode,
      profanity_filter: filterProfanity,
    };
  },

  [ActionType.OPENED_SHARING_DIALOG]: (state) => {
    return {
      ...state,
      opened_sharing: true,
    };
  },

  [ActionType.CHANGED_SHARING_SETTINGS]: (state, payload) => {
    return {
      ...state,
      changed_public_sharing: getSharingLabel(payload),
    };
  },

  [ActionType.COPIED_SHARING_LINK]: (state) => {
    return {
      ...state,
      copied_sharing_link: true,
    };
  },

  [ActionType.FLUSH]: (state, { emittedActions, reason }) => {
    return emittedActions || reason !== Constants.PACKAGE_SIZE_EXCEEDED
      ? null
      : state;
  },
});

export function reduceActions(
  state: State,
  action: AnalyticsData.EventsAction
): State {
  const ensuredState =
    state || createAnalyticsActions(action.payload.timestamp);
  return reduce(ensuredState, action);
}
