export namespace StartUpTasks {
  export enum Demo {
    Save = "demo/save",
    UnlockRequest = "demo/request",
    // This is translated to #RecoveredSong.Share
    Share = "demo/share",
  }

  export enum RecoveredSong {
    Open = "recovered-songs/open",
    Share = "recovered-songs/share",
  }

  export type Any = Demo | RecoveredSong;

  export function isDemoTask(task: Any): task is Demo {
    return task.startsWith("demo/");
  }
}
