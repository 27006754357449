import * as Sentry from "@sentry/nextjs";
import { config } from "./config";

Sentry.init({
  dsn: config.sentry.dsn,
  tracesSampleRate: 0.1,
  environment: config.env,
  normalizeDepth: 6,
  enabled: ["production", "stage"].includes(config.env),
});
