import { RawSong, Song, AnySongContent } from "../types/song";
import { EMPTY_CONTENT } from "./slate-utils";
import { RawDraftContentState } from "draft-js";
import { Descendant } from "slate";

export function convertRawSongToSong(song: RawSong): Song<Descendant[]> {
  return {
    ...song,
    content: song.content ? JSON.parse(song.content) : EMPTY_CONTENT,
    createdAt: new Date(song.createdAt),
    updatedAt: new Date(song.updatedAt),
  };
}

export function checkIsDraftJsContent(
  content: AnySongContent
): content is RawDraftContentState {
  return "blocks" in content;
}
