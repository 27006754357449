import { AxiosError } from "axios";
import { ActionTypes } from "../action-types";
import { convertAutoLinesToList, convertAutoWordsToList } from "../helper";
import {
  AutoLoadingPayload,
  AutoState,
  AutoSuccessPayload,
  ClearPayload,
} from "../types";
import { createReducer } from "@reduxjs/toolkit";
import { makeAddCaseFn } from "../../../utils/redux-utils";
import { uniqBy } from "lodash";

export const INITIAL_STATE: AutoState = {
  remote: {
    words: {
      status: "idle",
      error: undefined,
    },
    lines: {
      status: "idle",
      error: undefined,
    },
  },
  local: {
    words: {
      items: [],
      hasMore: true,
      loadedMore: false,
    },
    lines: {
      items: [],
      hasMore: true,
      loadedMore: false,
    },
  },
  loadedOnce: false,
  cleared: true,
};

export const autoReducer = createReducer(INITIAL_STATE, (builder) => {
  const addCase = makeAddCaseFn(builder);

  addCase<AutoLoadingPayload>(
    ActionTypes.FETCH_AUTO_WORDS_LOADING,
    (state, { payload }) => {
      if (payload.cleared) {
        state.remote.words = { ...INITIAL_STATE.remote.words };
        state.local.words = { ...INITIAL_STATE.local.words };
      }

      state.remote.words.status = "loading";
      state.loadedOnce = true;
      state.cleared = payload.cleared;
    }
  );

  addCase<AutoSuccessPayload>(
    ActionTypes.FETCH_AUTO_WORDS_SUCCEEDED,
    (state, { payload }) => {
      const newWords = convertAutoWordsToList(payload);
      const items = uniqBy([...state.local.words.items, ...newWords], "text");
      const hasMore = state.local.words.items.length < items.length;
      const loadedMore = state.local.words.items.length > 0;

      state.remote.words.status = "succeeded";
      state.local.words = { items, hasMore, loadedMore };
    }
  );

  addCase<AxiosError>(
    ActionTypes.FETCH_AUTO_WORDS_FAILED,
    (state, { payload }) => {
      state.remote.words.status = "failed";
      state.remote.words.error = payload;
    }
  );

  addCase<AutoLoadingPayload>(
    ActionTypes.FETCH_AUTO_LINES_LOADING,
    (state, { payload }) => {
      if (payload.cleared) {
        state.remote.lines = { ...INITIAL_STATE.remote.lines };
        state.local.lines = { ...INITIAL_STATE.local.lines };
      }

      state.remote.lines.status = "loading";
      state.loadedOnce = true;
    }
  );

  addCase<AutoSuccessPayload>(
    ActionTypes.FETCH_AUTO_LINES_SUCCEEDED,
    (state, { payload }) => {
      const newLines = convertAutoLinesToList(payload);
      const items = uniqBy([...state.local.lines.items, ...newLines], "text");
      const hasMore = state.local.lines.items.length < items.length;
      const loadedMore = state.local.lines.items.length > 0;

      state.remote.lines.status = "succeeded";
      state.local.lines.items = items;
      state.local.lines.hasMore = hasMore;
      state.local.lines.loadedMore = loadedMore;
    }
  );

  addCase<AxiosError>(
    ActionTypes.FETCH_AUTO_LINES_FAILED,
    (state, { payload }) => {
      state.remote.lines.status = "failed";
      state.remote.lines.error = payload;
    }
  );

  addCase<ClearPayload | undefined>(
    ActionTypes.CLEAR_AUTO,
    (state, { payload }) => {
      if (!payload?.keepStatus) {
        return INITIAL_STATE;
      }

      state.remote = { ...INITIAL_STATE.remote };
      state.local = { ...INITIAL_STATE.local };
    }
  );
});
