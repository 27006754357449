import { InferenceApiResult } from "../api/inference";
import Constants from "../constants";
import { Diversity } from "../constants/diversity";
import { Mood } from "../constants/moods";
import { SelectionType } from "../constants/selection-type";
import { ActionType } from "../data/analytics/actionTypes";
import { Suggestion } from "../data/suggestions/types";
import { Mode } from "../types/mode";

// TODO This type should not be defined here
type Song = {
  id: string;
  title: string;
  content: string;
};

export type SettingsChanges = {
  diversity: Diversity;
  genre: string;
  filterProfanity: boolean;
  darkmode: boolean;
  autocomplete: boolean;
};

export type AccessMode = "editor" | "viewer";

export type AnalyticsEventPayload = Partial<{
  callId: string;
  mode: Mode;
  response: InferenceApiResult<unknown>;
  suggestion: Suggestion;
  selectionType: SelectionType;
  previous: string;
  current: string;
  settings: SettingsChanges;
  length: number;
  song: Song;
  accessMode: AccessMode;
  public: boolean;
  url: string;
  reason: string;
  eventsLength: number;
  emittedActions: boolean;
  word: string;
  refresh: boolean;
  loadedMore: boolean;
  shortcut: boolean;
  songMood: Mood["value"];
}>;

export function createEvent(
  name: ActionType,
  payload: AnalyticsEventPayload = {}
) {
  return new CustomEvent(Constants.ANALYTICS_EVENT, {
    detail: { name, payload, timestamp: new Date().toISOString() },
  });
}

export function addRequestedSuggestionsEvent(
  callId: string,
  mode: Mode,
  response: InferenceApiResult<unknown>,
  loadedMore?: boolean
) {
  dispatchEvent(
    createEvent(ActionType.REQUESTED_SUGGESTIONS, {
      callId,
      mode,
      response,
      loadedMore,
    })
  );
}

export function addRefreshedSuggestionsEvent(mode: Mode) {
  dispatchEvent(createEvent(ActionType.REFRESHED_SUGGESTIONS, { mode }));
}

export function addSelectedSuggestionEvent(
  suggestion: Suggestion,
  selectionType: SelectionType
) {
  dispatchEvent(
    createEvent(ActionType.SELECTED_SUGGESTION, {
      suggestion,
      selectionType,
    })
  );
}

export function addChangedModeEvent(previous: string, current: string) {
  dispatchEvent(createEvent(ActionType.CHANGED_MODE, { previous, current }));
}

export function addChangedSettingsEvent(settings: SettingsChanges) {
  dispatchEvent(createEvent(ActionType.CHANGED_SETTINGS, { settings }));
}

export function addUserTypedEvent(length: number) {
  dispatchEvent(createEvent(ActionType.USER_TYPED, { length }));
}

export function addOpenedLyricsEvent(
  song: Song,
  accessMode: AccessMode = "editor"
) {
  dispatchEvent(createEvent(ActionType.OPENED_LYRICS, { song, accessMode }));
}

export function addClosedLyricsEvent(
  song: Song,
  accessMode: AccessMode = "editor"
) {
  dispatchEvent(createEvent(ActionType.CLOSED_LYRICS, { song, accessMode }));
}

export function addOpenedSharingDialog() {
  dispatchEvent(createEvent(ActionType.OPENED_SHARING_DIALOG));
}

export function addChangedSharingSettings({
  public: isPublic,
}: {
  public: boolean;
}) {
  dispatchEvent(
    createEvent(ActionType.CHANGED_SHARING_SETTINGS, {
      public: isPublic,
    })
  );
}

export function addCopiedSharingLink(url: string) {
  dispatchEvent(createEvent(ActionType.COPIED_SHARING_LINK, { url }));
}

export function addHidAssistantEvent() {
  dispatchEvent(createEvent(ActionType.HID_ASSISTANT));
}

export function addShowedAssistantEvent() {
  dispatchEvent(createEvent(ActionType.SHOWED_ASSISTANT));
}

export function addClickedLockedSuggestionEvent(suggestion: Suggestion) {
  dispatchEvent(
    createEvent(ActionType.CLICKED_LOCKED_SUGGESTION, { suggestion })
  );
}

export function addClickedLockedMetricEvent() {
  dispatchEvent(createEvent(ActionType.CLICKED_LOCKED_METRIC));
}

export function addClickedLockedLoadMoreEvent() {
  dispatchEvent(createEvent(ActionType.CLICKED_LOCKED_LOAD_MORE));
}

export function addReloadedSuggestionsEvent(shortcut: boolean) {
  dispatchEvent(createEvent(ActionType.RELOADED_SUGGESTIONS, { shortcut }));
}

export function addClickedUpgradeEvent() {
  dispatchEvent(createEvent(ActionType.CLICKED_UPGRADE));
}

export function addChangedSongMoodEvent(songMood: Mood["value"]) {
  dispatchEvent(createEvent(ActionType.CHANGED_SONG_MOOD, { songMood }));
}
