import { useCallback, useEffect, useRef } from "react";
import { StartUpTasks } from "../constants/start-up-tasks";
import { removeIndex } from "../utils/array-utils";
import { SongRecovery } from "./use-recover-locally-stored-song";

export type SpendFn = {
  (task: string): boolean;
};

export function useStartUpTasks({
  songRecovery,
  task,
}: {
  songRecovery: SongRecovery;
  task?: string;
}) {
  const startUpTasks = useRef<StartUpTasks.RecoveredSong[]>([]);

  useEffect(() => {
    const hasDemo = songRecovery.hasData;
    const shouldOpenShare = task === StartUpTasks.Demo.Share;

    startUpTasks.current = [
      hasDemo && StartUpTasks.RecoveredSong.Open,
      hasDemo && shouldOpenShare && StartUpTasks.RecoveredSong.Share,
    ].filter(Boolean) as StartUpTasks.RecoveredSong[];
  }, [songRecovery.hasData, task]);

  return useCallback(
    (fn: SpendFn) => {
      const spentIndex = startUpTasks.current.findIndex(fn);

      if (spentIndex !== -1) {
        startUpTasks.current = removeIndex(startUpTasks.current, spentIndex);
      }
    },
    [startUpTasks]
  );
}
