import { Diversity } from "../../constants/diversity";
import { Mood } from "../../constants/moods";
import { Mode } from "../../types/mode";
import { RequestType } from "../../types/request-type";

export type SongSettingsOptions = Partial<{
  genre: string;
  filterProfanity: boolean;
  diversity: Diversity;
  mood: Mood["value"];
  metric: number | null;
}>;

export type ExperimentOptions = Partial<{
  group: string;
  beta: string;
}>;

export type ApiOptions = Partial<{
  demo: boolean;
}>;

export type RhymesOptions = Partial<{
  random: boolean;
  sample_size: number;
}>;

export type AllOptions = SongSettingsOptions &
  ExperimentOptions &
  ApiOptions &
  RhymesOptions;

export type Params = Partial<{
  lyrics: string;
  genre: string;
  n_verses: number;
  remove_profanity: boolean;
  diversity: Diversity;
  word: string;
  "rhyme-word": string;
  model: string;
  group: string;
  beta: string;
  part_of_speech: null;
  syllables: number;
  mood: string;
}>;

export type InferenceApiResult<T> = {
  requestId: string;
  requestType: RequestType;
  params: Params;
  data: T;
  headers: Record<string, string>;
};

export type CommonInferenceResult<T = string> = InferenceApiResult<T[]>;

export type RhymesInferenceResult = InferenceApiResult<{
  words: string[];
  lines: string[];
}>;

export type ThesaurusInferenceResult = InferenceApiResult<{
  antonym: string[];
  synonym: string[];
  definition: { definition: string; part_of_speech: string }[];
  word: string;
}>;

export type IDiversity = {
  label: string;
  value: Diversity;
};

/* Type-testing methods  */

export function isRhymesResult(
  mode: Mode,
  result: InferenceApiResult<unknown>
): result is RhymesInferenceResult {
  return mode === Mode.RHYMES && result.requestType === RequestType.RHYMES;
}

export function isRhymeWordsResult(
  mode: Mode,
  result: InferenceApiResult<unknown>
): result is CommonInferenceResult<string> {
  return mode === Mode.RHYMES && result.requestType === RequestType.RHYME_WORDS;
}

export function isThesaurusResult(
  mode: Mode,
  result: InferenceApiResult<unknown>
): result is ThesaurusInferenceResult {
  return mode === Mode.THESAURUS;
}
