import { useCallback, useRef } from "react";
import { AnyFunction } from "../types/common";

type DebouncedFn<T> = T & { immediately: T };

/**
 * Debounce function hook.
 */
function useDebounceCall<T extends AnyFunction>(
  fn: T,
  delay: number
): DebouncedFn<T> {
  const fnRef = useRef<T | null>(null);
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  fnRef.current = fn;

  const callback = useCallback(
    function (
      this: { shouldCallImmediately?: boolean },
      ...args: unknown[]
    ): void {
      clearTimeout(timeoutIdRef.current);

      if (this?.shouldCallImmediately) {
        fnRef.current?.(...args);
      } else {
        timeoutIdRef.current = setTimeout(
          () => fnRef.current?.(...args),
          delay
        );
      }
    },
    [delay]
  ) as DebouncedFn<T>;

  // Creates a wrapper function that triggers the function call immediately instead of debouncing
  callback.immediately = ((...args: unknown[]) => {
    return callback.apply({ shouldCallImmediately: true }, args);
  }) as T;

  return callback;
}

export default useDebounceCall;
