import { useEffect } from "react";

/**
 * Timeout hook that resets the callback function when a dependency changes.
 * @param {() => void} callback function to call
 * @param {number} delay time to wait before calling the callback (ms)
 * @param {unknown[]} dependencies array of dependencies
 */
function useTimeout(callback, delay, dependencies) {
  useEffect(() => {
    const id = setTimeout(callback, delay);
    return () => clearTimeout(id);
  }, dependencies);
}

export default useTimeout;
