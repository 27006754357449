type AnyObject = Record<string, unknown>;

/**
 * Reads data from the session storage and parses to JSON.
 */
function getItem<T extends AnyObject = AnyObject>(key: string): T | null {
  const item = sessionStorage.getItem(key);

  if (!item) {
    return null;
  }

  return JSON.parse(item);
}

/**
 * Stores data in the session storage as JSON.
 */
function setItem(key: string, data: AnyObject): void {
  const item = JSON.stringify(data);
  sessionStorage.setItem(key, item);
}

export const SessionJsonStorage = { getItem, setItem };
