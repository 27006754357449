import Constants from "../../constants";
import { ensureArray } from "../../utils/array-utils";
import { Mode } from "../../types/mode";
import { SuggestionType } from "../../types/suggestion-type";
import {
  AutoSuccessPayload,
  FirstRhymesPayload,
  NextRhymesPayload,
  Suggestion,
  SuccessThesaurusPayload,
} from "./types";

function normalizeText(text: string) {
  return text.trimEnd().replace("\n ", "\n");
}

function minifyText(text: string) {
  return text.trim().toLowerCase();
}

export function convertAutoWordsToList(
  payload: AutoSuccessPayload
): Suggestion[] {
  return ensureArray(payload.result?.data).map((text, index) => ({
    callId: payload.callId,
    requestId: payload.result.requestId,
    requestType: payload.result.requestType,
    mode: Mode.AUTO,
    type: SuggestionType.WORDS,
    text,
    index,
    highlight: false,
    minified: minifyText(text),
  }));
}

export function convertAutoLinesToList(
  payload: AutoSuccessPayload
): Suggestion[] {
  return ensureArray(payload.result?.data).map((text, index) => ({
    callId: payload.callId,
    requestId: payload.result.requestId,
    requestType: payload.result.requestType,
    mode: Mode.AUTO,
    type: SuggestionType.LINES,
    text: normalizeText(text),
    index,
    highlight: false,
    minified: minifyText(normalizeText(text)),
  }));
}

export function convertRhymeWordsToList(
  payload: FirstRhymesPayload
): Suggestion[] {
  return ensureArray(payload.rhymeWords?.data).map((text, index) => ({
    callId: payload.callId,
    requestId: payload.rhymeWords.requestId,
    requestType: payload.rhymeWords.requestType,
    mode: Mode.RHYMES,
    type: SuggestionType.WORDS,
    text,
    index,
    highlight: false,
    minified: minifyText(text),
  }));
}

export function convertRhymesToLists(
  payload: FirstRhymesPayload | NextRhymesPayload
): {
  words: Suggestion[];
  lines: Suggestion[];
} {
  const words = ensureArray(payload.rhymes?.data.words).map((text, index) => ({
    callId: payload.callId,
    requestId: payload.rhymes.requestId,
    requestType: payload.rhymes.requestType,
    mode: Mode.RHYMES,
    type: SuggestionType.WORDS,
    text,
    index,
    highlight: true,
    minified: minifyText(text),
  }));

  const lines = ensureArray(payload.rhymes?.data.lines).map((text, index) => ({
    callId: payload.callId,
    requestId: payload.rhymes.requestId,
    requestType: payload.rhymes.requestType,
    mode: Mode.RHYMES,
    type: SuggestionType.LINES,
    text: normalizeText(text),
    index,
    highlight: false,
    minified: minifyText(normalizeText(text)),
  }));

  return { words, lines };
}

export function convertThesaurusDefinitionsToList(
  payload: SuccessThesaurusPayload
): Suggestion[] {
  return ensureArray(payload.result?.data.definition)
    .slice(0, Constants.MAX_DEFINITIONS)
    .map((item, index) => ({
      callId: payload.callId,
      requestId: payload.result.requestId,
      requestType: payload.result.requestType,
      mode: Mode.THESAURUS,
      type: SuggestionType.DEFINITIONS,
      text: item.definition,
      index,
      highlight: false,
      minified: minifyText(item.definition),
    }));
}

export function convertThesaurusSynonymsToList(
  payload: SuccessThesaurusPayload
): Suggestion[] {
  return ensureArray(payload.result?.data.synonym)
    .slice(0, Constants.MAX_WORDS)
    .map((text, index) => ({
      callId: payload.callId,
      requestId: payload.result.requestId,
      requestType: payload.result.requestType,
      mode: Mode.THESAURUS,
      type: SuggestionType.SYNONYMS,
      text,
      index,
      highlight: false,
      minified: minifyText(text),
    }));
}

export function convertThesaurusAntonymsToList(
  payload: SuccessThesaurusPayload
): Suggestion[] {
  return ensureArray(payload.result?.data.antonym)
    .slice(0, Constants.MAX_WORDS)
    .map((text, index) => ({
      callId: payload.callId,
      requestId: payload.result.requestId,
      requestType: payload.result.requestType,
      mode: Mode.THESAURUS,
      type: SuggestionType.ANTONYMS,
      text,
      index,
      highlight: false,
      minified: minifyText(text),
    }));
}
