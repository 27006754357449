import { config } from "../config";
import { SessionEvents } from "../constants";
import { SelectionType } from "../constants/selection-type";
import { StartUpTasks } from "../constants/start-up-tasks";
import { AnalyticsData } from "../data/analytics/types";
import { RequestType } from "../types/request-type";
import { SuggestionType } from "../types/suggestion-type";
import { uuid } from "./crypto-utils";

const ACTIONS_VERSION = 2;
const EVENTS_VERSION = 3;

const SESSION_ACTIONS_INITIAL_STATE: AnalyticsData.Actions = {
  timestamp_session_start: null,
  timestamp_session_end: null,
  session_duration: 0,
  chars_added_by_user: 0,
  chars_added_by_model: 0,
  clicked_words_auto: 0,
  clicked_lines_auto: 0,
  clicked_words_rhymes: 0,
  clicked_lines_rhymes: 0,
  clicked_synonyms: 0,
  clicked_antonyms: 0,
  written_words_auto: 0,
  written_lines_auto: 0,
  written_words_rhymes: 0,
  written_lines_rhymes: 0,
  written_synonyms: 0,
  written_antonyms: 0,
  auto_completed_words_auto: 0,
  auto_completed_lines_auto: 0,
  auto_completed_words_rhymes: 0,
  auto_completed_lines_rhymes: 0,
  auto_completed_synonyms: 0,
  auto_completed_antonyms: 0,
  api_requests_auto: 0,
  api_requests_rhymes: 0,
  api_requests_thesaurus: 0,
  clicked_in_thesaurus: false,
  chosen_genres: [],
  chosen_creativity: [],
  words_empty_requests_rhymes: [],
  words_empty_requests_thesaurus: [],
  dark_mode: true,
  profanity_filter: true,
  opened_sharing: false,
  changed_public_sharing: null,
  copied_sharing_link: false,
};

export function createAnalyticsActions(
  timestamp?: string
): AnalyticsData.Actions {
  return {
    ...SESSION_ACTIONS_INITIAL_STATE,
    timestamp_session_start: timestamp || new Date().toISOString(),
  };
}

/**
 * Creates the session analytics initial state.
 */
export function createAnalyticsState(): AnalyticsData.State {
  return { actions: null, events: [], eventsSize: 0 };
}

export function makeActionsBackwardsCompatible(actions: AnalyticsData.Actions) {
  return {
    ...actions,
    chosen_words_auto: actions.clicked_words_auto,
    chosen_lines_auto: actions.clicked_lines_auto,
    chosen_words_rhymes: actions.clicked_words_rhymes,
    chosen_lines_rhymes: actions.clicked_lines_rhymes,
    chosen_synonyms: actions.clicked_synonyms,
    chosen_antonyms: actions.clicked_antonyms,
    api_requets_auto: actions.api_requests_auto,
    api_requets_rhymes: actions.api_requests_rhymes,
    api_requets_thesaurus: actions.api_requests_thesaurus,
  };
}

/**
 * Transforms the analytics data into a session.
 */
export function transformAnalyticsActions(
  sessionId: string,
  actions: AnalyticsData.Actions,
  reason: string,
  task?: StartUpTasks.Any,
  experimentTag?: string
): AnalyticsData.ActionsPacket {
  // The events of USER_TYPED also includes the text inserted from suggestions, so the real
  // count of characters inserted by the user is calculated below
  const charsAddedByUser = Math.max(
    actions.chars_added_by_user - actions.chars_added_by_model,
    0
  );

  return {
    version: ACTIONS_VERSION,
    task,
    session_package_id: uuid(),
    session_id: sessionId,
    session_end_reason: reason,
    env: config.env,
    exp_tag: experimentTag,
    ...makeActionsBackwardsCompatible(actions),
    chars_added_by_user: charsAddedByUser,
  };
}

/**
 * Make an event backwards compatible. Currently, it makes version 2 compatible with version 1.
 */
function makeEventBackwardsCompatible(event: AnalyticsData.Event) {
  const payload = event.payload as {
    requestType: RequestType;
    type: SuggestionType;
    selectionType: SelectionType;
  };

  switch (event.name) {
    case SessionEvents.SUGGESTION_REQUEST:
      return {
        ...event,
        payload: { ...payload, type: payload.requestType },
      };

    case SessionEvents.SUGGESTION_SELECTED:
      return {
        ...event,
        payload: {
          ...payload,
          type: payload.requestType,
          modeType: payload.type,
          written: payload.selectionType === SelectionType.WRITTEN,
          autoCompleted: payload.selectionType === SelectionType.AUTO_COMPLETED,
        },
      };

    case SessionEvents.CLICKED_LOCKED_LOAD_MORE:
      return {
        ...event,
        payload: {
          ...payload,
          type: payload.requestType,
          modeType: payload.type,
        },
      };

    default:
      return event;
  }
}

/**
 * Transforms the analytics data into a session.
 */
export function transformAnalyticsEvents(
  sessionId: string,
  events: AnalyticsData.Event[],
  task?: StartUpTasks.Any,
  experimentTag?: string
): AnalyticsData.EventsPacket {
  return {
    version: EVENTS_VERSION,
    session_package_id: uuid(),
    session_id: sessionId,
    env: config.env,
    events: events.map(makeEventBackwardsCompatible),
    task,
    exp_tag: experimentTag,
  };
}

const SESSION_ACTIONS_RULES = [
  // Should have the end timestamp, which ensures that there was at least one user action
  (actions: AnalyticsData.Actions) => actions.timestamp_session_end,
  // The application usually does two requests at the beginning of the session, so the session
  // should have more than that to be considered
  (actions: AnalyticsData.Actions) => {
    const totalRequests =
      actions.api_requests_auto +
      actions.api_requests_rhymes +
      actions.api_requests_thesaurus;
    return totalRequests > 2;
  },
  // Should have at least 5 seconds of duration
  (actions: AnalyticsData.Actions) => actions.session_duration >= 5,
];

/**
 * Check if the session actions are valid.
 */
export function checkSessionActions(
  actions: AnalyticsData.Actions | null
): boolean {
  return Boolean(
    actions && SESSION_ACTIONS_RULES.every((rule) => rule(actions))
  );
}

/**
 * Creates a session event.
 */
export function createSessionEvent(
  name: string,
  payload: unknown,
  timestamp: string
): AnalyticsData.Event {
  return {
    name,
    payload,
    timestamp: timestamp || new Date().toISOString(),
  };
}
