import * as apolloClient from "@apollo/client";
import {
  DocumentNode,
  MutationHookOptions,
  QueryHookOptions,
} from "@apollo/client";
import { isNil } from "lodash";
import { useTrueOnce } from "./use-true-once";

export namespace GraphQLHooks {
  export type VariablesFn<TArgs extends unknown[]> = {
    (...args: TArgs): Record<string, unknown>;
  };

  export type ProcessFn<TRawResult, TResult> = {
    (data: TRawResult): TResult;
  };

  export type CustomOptions<TRawResult, TResult, TArgs extends unknown[]> = {
    variablesFn?: VariablesFn<TArgs>;
    processFn?: ProcessFn<TRawResult, TResult>;
    raiseErrors?: boolean;
  };

  export type QueryResult<TResult> = {
    loading: boolean;
    error: Error | null;
    value: TResult | null;
    didOnce: boolean;
  };

  export type RefetchFn<TArgs extends unknown[]> = {
    (...args: TArgs): Promise<void>;
  };

  export type MutateFn<TArgs extends unknown[]> = RefetchFn<TArgs>;
}

export function useQuery<
  TRawResult = unknown,
  TResult = null,
  TArgs extends unknown[] = []
>(
  query: DocumentNode,
  {
    variablesFn,
    processFn,
    ...options
  }: QueryHookOptions &
    GraphQLHooks.CustomOptions<TRawResult, TResult, TArgs> = {}
): [GraphQLHooks.QueryResult<TResult>, GraphQLHooks.RefetchFn<TArgs>] {
  const { loading, error, data, refetch } = apolloClient.useQuery<TRawResult>(
    query,
    {
      fetchPolicy: "network-only",
      errorPolicy: "all",
      ...options,
    }
  );
  const didOnce = useTrueOnce(loading || error || data);
  const value = error || isNil(data) ? null : processFn?.(data) ?? null;

  async function fn(...args: TArgs) {
    await refetch(variablesFn?.(...args));
  }

  return [{ loading, error: error || null, value, didOnce }, fn];
}

export function useMutation<
  TRawResult = unknown,
  TResult = null,
  TArgs extends unknown[] = []
>(
  mutation: DocumentNode,
  {
    variablesFn,
    processFn,
    raiseErrors,
    ...options
  }: MutationHookOptions &
    GraphQLHooks.CustomOptions<TRawResult, TResult, TArgs> = {}
): [GraphQLHooks.QueryResult<TResult>, GraphQLHooks.MutateFn<TArgs>] {
  const [mutate, { loading, error, data }] = apolloClient.useMutation(
    mutation,
    {
      fetchPolicy: "network-only",
      errorPolicy: "all",
      ...options,
    }
  );

  const didOnce = useTrueOnce(loading || error || data);
  const value = error || isNil(data) ? null : processFn?.(data) ?? null;

  async function fn(...args: TArgs) {
    try {
      await mutate({ variables: variablesFn?.(...args) });
    } catch (error) {
      if (raiseErrors) {
        throw error;
      }
    }
  }

  return [{ loading, error: error || null, value, didOnce }, fn];
}
