import * as Sentry from "@sentry/nextjs";

export type FeatureFlags = {
  model?: string;
  abTest?: "disabled" | "control" | "a";
  betaTest?: "disabled" | "control" | "a";
  testFeature: {
    type: "off" | "beta" | "ab";
    key?: string;
    tag?: string;
    flow?: string;
  };
  freeUserSongsLimit: number;
  studioAbTest: boolean;
};

export const DEFAULT_FEATURE_FLAGS: FeatureFlags = {
  testFeature: { type: "off" },
  freeUserSongsLimit: Infinity,
  studioAbTest: false,
};

function parseFreeUserSongsLimit(value: number | undefined): number {
  return typeof value === "number" && value >= 0 ? value : Infinity;
}

/**
 * Parses the feature flags defined by the parent window. It's base64 encoded.
 */
export function extractFeatureFlags(encodedData?: string): FeatureFlags {
  if (!encodedData) {
    return DEFAULT_FEATURE_FLAGS;
  }

  try {
    const decoded = Buffer.from(encodedData, "base64").toString();
    const data = JSON.parse(decoded);
    return {
      abTest: data.aiSongwriterAbTest,
      betaTest: data.aiSongwriterBetaTest,
      testFeature: data.aiSongwriterTestFeature,
      freeUserSongsLimit: parseFreeUserSongsLimit(
        data.aiSongwriterFreeUserSongsLimit
      ),
      studioAbTest: data.abTestWeb === "true",
    };
  } catch (error) {
    Sentry.captureException(error, { extra: { encodedData } });
    return DEFAULT_FEATURE_FLAGS;
  }
}
