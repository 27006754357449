import {
  MUTATION_ACCEPT_TERMS,
  MUTATION_CREATE_SONG,
  MUTATION_DELETE_SONG,
  MUTATION_MARK_HAS_SEEN_LANGUAGE_WARNING,
  MUTATION_REPORT_SUGGESTION,
  MUTATION_UPDATE_USER,
  QUERY_SONGS,
  QUERY_USER,
} from "./graphql-operations";
import Constants, { DEFAULT_SONG_OPTIONS } from "../../constants";
import { useMutation, useQuery } from "../../hooks/graphql-hooks";
import { CreateInitialSongInput, ListingSong, RawSong } from "../../types/song";
import { pick } from "lodash";
import { clearNil } from "../../utils/object-utils";
import { Suggestion } from "../../data/suggestions/types";
import { extractLinesFromContent } from "../../utils/slate-utils";
import { StartUpTasks } from "../../constants/start-up-tasks";
import { MOODS } from "../../constants/moods";
import { User } from "./types";
import { minifyTextForSearching } from "../../utils/text-utils";
import { Descendant } from "slate";

type Options = { supressNotAuthorizedAlert: boolean };

/* User */

export const useUser = (options?: Options) =>
  useQuery<{ me: User }, User>(QUERY_USER, {
    processFn: (data) => data.me,
    context: { options },
  });

export const useUpdateUser = (options?: Options) =>
  useMutation<null, null, [onboardingStep: number]>(MUTATION_UPDATE_USER, {
    variablesFn: (onboardingStep) => ({ data: { onboardingStep } }),
    context: { options },
  });

export const useAcceptTerms = (task?: StartUpTasks.Any, options?: Options) =>
  useMutation(MUTATION_ACCEPT_TERMS, {
    variables: { data: { task } },
    context: { options },
  });

export const useMarkHasSeenLanguageWarning = (options?: Options) =>
  useMutation(MUTATION_MARK_HAS_SEEN_LANGUAGE_WARNING, {
    context: { options },
    refetchQueries: [{ query: QUERY_USER }],
  });

/* Song */

export const useFetchSongs = (options?: Options) =>
  useQuery<{ songs: RawSong[] }, ListingSong[]>(QUERY_SONGS, {
    processFn: (data) =>
      data.songs.map((song) => {
        const content = song.content
          ? (JSON.parse(song.content) as Descendant[])
          : null;
        const contentLines = content && extractLinesFromContent(content);
        const contentText = contentLines?.join("\n") || "";

        return {
          ...song,
          content: contentText || "",
          minifiedTitle: minifyTextForSearching(song.title),
          minifiedContent: minifyTextForSearching(contentText),
          shortContent: contentLines?.slice(0, 4).join("\n") || "",
          updatedAt: new Date(song.updatedAt),
          createdAt: new Date(song.createdAt),
        };
      }),
    context: { options },
  });

export const useCreateSong = (options?: Options) =>
  useMutation<{ createSong: { id: string } }, { id: string }>(
    MUTATION_CREATE_SONG,
    {
      variables: {
        data: {
          title: Constants.DEFAULT_SONG_TITLE,
          diversity: Constants.DEFAULT_DIVERSITY,
          genre: Constants.DEFAULT_GENRE,
          filterProfanity: Constants.DEFAULT_FILTER_PROFANITY,
          mood: Constants.DEFAULT_MOOD,
        },
      },
      processFn: (data) => pick(data.createSong, "id"),
      context: { options },
    }
  );

export const useDeleteSong = (options?: Options) =>
  useMutation<null, null, [id: string]>(MUTATION_DELETE_SONG, {
    variablesFn: (id) => ({ id }),
    context: { options },
  });

const DEFAULT_SONG = {
  ...DEFAULT_SONG_OPTIONS,
  title: Constants.DEFAULT_SONG_TITLE,
  mood: MOODS.neutral.value,
};

export const useCreateInitialSong = (options?: Options) =>
  useMutation<
    { createSong: { id: string } },
    { id: string },
    [input: CreateInitialSongInput]
  >(MUTATION_CREATE_SONG, {
    variablesFn: (data) => ({
      data: pick(
        {
          ...DEFAULT_SONG,
          ...clearNil(data),
          content: JSON.stringify(data.content),
        },
        "title",
        "content",
        "diversity",
        "genre",
        "filterProfanity",
        "mood",
        "metric"
      ),
    }),
    processFn: (data) => ({ id: data?.createSong.id }),
    context: { options },
  });

/* Reports */

export const useReportSuggestion = (
  sessionId: string | undefined,
  songId: string,
  options?: Options
) =>
  useMutation<null, null, [suggestion: Suggestion, reason: string]>(
    MUTATION_REPORT_SUGGESTION,
    {
      variablesFn: (suggestion: Suggestion, reason: string) => ({
        data: {
          songId,
          session: sessionId,
          call: suggestion.callId,
          request: suggestion.requestId,
          text: suggestion.text,
          reason,
        },
      }),
      ignoreResults: true,
      context: { options },
    }
  );
