import Constants from "../constants";
import {
  ensureSongsLimitRedirectionUrl,
  parseBooleanEnvVar,
  parseNumberEnvVar,
} from "../utils/env-utils";

const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV || Constants.ENV_DEVELOPMENT;

export const config = {
  env: APP_ENV,
  appUrl: process.env.NEXT_PUBLIC_APP_URL,
  parentUrl: process.env.NEXT_PUBLIC_PARENT_URL,
  inferenceApiHost: process.env.NEXT_PUBLIC_COMPOSER_API_HOST,
  demoInferenceApiHost: process.env.NEXT_PUBLIC_COMPOSER_DEMO_API_HOST,
  serviceApiHost: process.env.NEXT_PUBLIC_SERVICE_API_HOST,
  hotjarEnabled: APP_ENV === Constants.ENV_PRODUCTION,
  analyticsSessionExpirationTime: parseNumberEnvVar(
    process.env.NEXT_PUBLIC_SESSION_EXPIRATION_TIME,
    3 * Constants.MINUTE
  ),
  // The rudderstack package limit is 32 KB
  // Source: https://documenter.getpostman.com/view/16242548/TzeWFT6D
  maxAnalyticsPackageSize: parseNumberEnvVar(
    process.env.NEXT_PUBLIC_MAX_ANALYTICS_PACKAGE_SIZE,
    // Prevents loosing a package by getting too close to the rudderstack limit
    // 85% of the rudderstack limit subtracted by the rudderstack payload average size 1.36 KB
    (0.85 * 32 - 1.36) * Constants.KB
  ),
  enableTemporaryAuth: parseBooleanEnvVar(
    process.env.NEXT_PUBLIC_ENABLE_TEMPORARY_AUTH,
    APP_ENV !== Constants.ENV_PRODUCTION
  ),
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  disableMobilePlaceholder: parseBooleanEnvVar(
    process.env.NEXT_PUBLIC_DISABLE_MOBILE_PLACEHOLDER,
    false
  ),
  songsLimitRedirectionUrl: ensureSongsLimitRedirectionUrl(
    process.env.NEXT_PUBLIC_SONGS_LIMIT_REDIRECTION_URL
  ),
  allowDirectAccess: parseBooleanEnvVar(
    process.env.NEXT_PUBLIC_ALLOW_DIRECT_ACCESS,
    APP_ENV === "test"
  ),
  pricingSource: process.env.PUBLIC_NEXT_PRICING_SOURCE || "lyrics_writer_banner",
  rhymesSample: parseNumberEnvVar(process.env.PUBLIC_NEXT_RHYMES_SAMPLE, null),
  minimumMetric: parseNumberEnvVar(process.env.PUBLIC_NEXT_MINIMUM_METRIC, 1),
  maximumMetric: parseNumberEnvVar(process.env.PUBLIC_NEXT_MAXIMUM_METRIC, 20),
  nSuggestionsPerFetch: parseNumberEnvVar(
    process.env.PUBLIC_NEXT_N_SUGGESTIONS_PER_FETCH,
    10
  ),
  freeSuggestionsProportion: parseNumberEnvVar(
    process.env.PUBLIC_NEXT_FREE_SUGGESTIONS_PROPORTION,
    0.4
  ),
};
