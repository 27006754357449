export enum ActionType {
  REQUESTED_SUGGESTIONS = "requested_suggestions",
  REFRESHED_SUGGESTIONS = "refreshed_suggestions",
  SELECTED_SUGGESTION = "selected_suggestion",
  CHANGED_MODE = "changed_mode",
  CHANGED_SETTINGS = "changed_settings",
  USER_TYPED = "user_typed",
  OPENED_LYRICS = "opened_lyrics",
  CLOSED_LYRICS = "closed_lyrics",
  FLUSH = "flush",
  OPENED_SHARING_DIALOG = "opened_sharing_dialog",
  CHANGED_SHARING_SETTINGS = "changed_sharing_settings",
  COPIED_SHARING_LINK = "copied_sharing_link",
  HID_ASSISTANT = "hid_assistant",
  SHOWED_ASSISTANT = "showed_assistant",
  CLICKED_LOCKED_SUGGESTION = "clicked_locked_suggestion",
  CLICKED_LOCKED_LOAD_MORE = "clicked_locked_load_more",
  CLICKED_LOCKED_METRIC = "clicked_locked_metric",
  RELOADED_SUGGESTIONS = "reloaded_suggestions",
  CLICKED_UPGRADE = "clicked_upgrade",
  CHANGED_SONG_MOOD = "changed_song_mood",
}
