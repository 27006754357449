import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { config } from "../../config";
import {
  handleClientOutdated,
  handleFailedToFetch,
  handleNotAuthorized,
} from "./graphql-error-handlers";
import { captureMessage } from "../../utils/debug";

/**
 * Wrapped fetch function to prevent errors when running on the server side.
 */
function fetch(...args: Parameters<typeof global.fetch>) {
  return global.fetch?.(...args);
}

const errorLink = onError((response) => {
  const errors = [
    response.networkError || [],
    response.graphQLErrors || [],
  ].flat();

  if (errors.length > 0) {
    const error =
      // Seems that the error that contains "extension" contains a more descriptive error message
      errors.find((error) => "extensions" in error && error.extensions.code) ||
      errors[0];

    captureMessage(`Service API request error: ${error.message}`, {
      level: "error",
      extra: {
        operation: response.operation.query.loc?.source.body,
        variables: response.operation.variables,
        data: response.response?.data,
        extensions: response.response?.extensions,
        errors,
      },
    });
  }

  handleFailedToFetch(response);
  handleNotAuthorized(response);
  handleClientOutdated(response);
});

const httpLink = new HttpLink({
  uri: `${config.serviceApiHost}/graphql`,
  credentials: "include",
  fetch,
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const rawUserData = localStorage.getItem("user-data");
    const userData = rawUserData && JSON.parse(rawUserData);

    return {
      headers: {
        ...headers,
        "x-user-id": userData?.userId,
      },
    };
  });

  return forward(operation);
});

export const serviceGraphqlClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
