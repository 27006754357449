import { gql } from "@apollo/client";

/* User */

export const QUERY_USER = gql`
  query Query {
    me {
      id
      acceptedTerms
      hasSeenLanguageWarning
      subscriptionPlan
      onboardingStep
    }
  }
`;

export const MUTATION_UPDATE_USER = gql`
  mutation UpdateUser($data: UserUpdateInput) {
    updateUser(data: $data)
  }
`;

export const MUTATION_ACCEPT_TERMS = gql`
  mutation AcceptTerms($data: UserInput) {
    acceptTerms(data: $data)
  }
`;

export const MUTATION_MARK_HAS_SEEN_LANGUAGE_WARNING = gql`
  mutation MarkHasSeenLanguageWarning {
    markHasSeenLanguageWarning
  }
`;

/* Song */

export const QUERY_SONGS = gql`
  query QuerySongs {
    songs {
      id
      title
      content
      public
      createdAt
      updatedAt
    }
  }
`;

export const QUERY_SONG = gql`
  query QuerySong($id: ID!) {
    song(id: $id) {
      id
      title
      content
      diversity
      genre
      filterProfanity
      mood
      metric
      public
      createdAt
      updatedAt
    }
  }
`;

export const MUTATION_CREATE_SONG = gql`
  mutation CreateSong($data: SongInput!) {
    createSong(data: $data) {
      id
    }
  }
`;

export const MUTATION_UPDATE_SONG = gql`
  mutation UpdateSong($id: ID!, $data: SongInput!) {
    updateSong(id: $id, data: $data) {
      language
      updatedAt
    }
  }
`;

export const MUTATION_DELETE_SONG = gql`
  mutation DeleteSong($id: ID!) {
    deleteSong(id: $id)
  }
`;

// Reports

export const MUTATION_REPORT_SUGGESTION = gql`
  mutation ReportSuggestion($data: ReportSuggestionInput!) {
    reportSuggestion(data: $data)
  }
`;

export const MUTATION_REPORT_SONG = gql`
  mutation ReportSong($data: ReportSongInput!) {
    reportSong(data: $data)
  }
`;
