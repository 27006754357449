export function scrollToElementBySelector(selector: string | undefined) {
  if (selector) {
    const keyElement = document.querySelector(selector);
    keyElement?.scrollIntoViewIfNeeded?.(false);
  }
}

export function scrollToElement(element: HTMLElement | null | undefined) {
  if (!element) {
    return;
  }

  element.scrollIntoView({ behavior: "smooth" });
}

/**
 * https://stackoverflow.com/a/26358856
 */
export function detectBrowser() {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) != -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Edg") != -1) {
    return "Edge";
  } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") != -1 ||
    !!document.documentMode == true
  ) {
    //IF IE > 10
    return "IE";
  } else {
    return null;
  }
}

/**
 * https://stackoverflow.com/a/21742107
 */
export function detectMobileOS() {
  const userAgent =
    navigator.userAgent || navigator.vendor || window.opera || "";

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return null;
}

export function checkIsInsideIFrame(): boolean {
  return window.location !== window.top?.location;
}
