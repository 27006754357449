import { FetchApiHookReturnValue } from "../..";
import { ApiOptions } from ".";
import InferenceApi from "./rest-api";
import { useFetchApi } from "../../hooks/use-fetch-api";

/**
 * Hook that calls GET /get_genres.
 */
export const useFetchGenres = (
  options: ApiOptions
): FetchApiHookReturnValue<string[]> =>
  useFetchApi(async () => {
    const { params, data } = await InferenceApi.fetchGenres(options);
    return { params, value: data, config: {} };
  });
