import Constants from "../constants";

type Options = {
  sticky?: boolean;
  needsRefresh?: boolean;
};

/**
 * Errors dispatched are handled by the ErrorHandler component.
 */
export function dispatchError(
  title: string,
  description: string,
  options: Options = {}
) {
  dispatchEvent(
    new CustomEvent(Constants.GLOBAL_ERROR_EVENT, {
      detail: { title, description, ...options },
    })
  );
}
