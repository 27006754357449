import { MutableRefObject } from "react";
import { useToggle } from "react-use";

/**
 * This is an anti-pattern. Avoid using this implementation.
 * It server only one purpose currently, which is holding the analytics data of the user.
 */
export function useStateRef<T extends Record<string, unknown>>(
  ref: MutableRefObject<T | undefined>,
  initFn: () => T
): [MutableRefObject<T>, (state: Partial<T>) => void] {
  // Triggers a re-render when the state changes
  const [, toggle] = useToggle(false);

  if (ref.current === undefined) {
    ref.current = initFn();
  }

  function setState(stateOrFn: Partial<T>): void {
    ref.current = { ...ref.current!, ...stateOrFn };
    toggle();
  }

  return [ref as MutableRefObject<T>, setState];
}
