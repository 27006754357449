import { EqualityFn, shallowEqual, useSelector } from "react-redux";
import * as Actions from "./actions";
import { useMemo } from "react";
import Constants from "../../constants";
import { useExperimentsContext } from "../../contexts/experiments-context";
import { difference, isEqual, pick } from "lodash";
import { useInDemo } from "../../hooks/use-in-demo";
import { useActions } from "../../hooks/use-actions";
import { DataMode, Mode } from "../../types/mode";
import { SongSettingsOptions } from "../../api/inference";
import { LocalState, ModeStateData, State, ThesaurusLocalState } from "./types";
import { AxiosError } from "axios";
import { Status } from "../../types/common";
import { SuggestionType } from "../../types/suggestion-type";
import { usePaywall } from "../../hooks/use-paywall";

function getFetchFn(
  actions: typeof Actions,
  mode: Mode,
  type?: SuggestionType
) {
  if (mode === Mode.AUTO) {
    if (type === SuggestionType.WORDS) {
      return actions.fetchAutoWords;
    }

    if (type === SuggestionType.LINES) {
      return actions.fetchAutoLines;
    }

    return actions.fetchAuto;
  }

  if (mode === Mode.RHYMES) {
    if (type === SuggestionType.WORDS) {
      return actions.fetchRhymeWords;
    }

    if (type === SuggestionType.LINES) {
      return actions.fetchRhymeLines;
    }

    return actions.fetchRhymes;
  }

  return actions.fetchThesaurus;
}

export function useSuggestionsActions(song: SongSettingsOptions | undefined) {
  const actions = useActions(Actions);
  const [experiment] = useExperimentsContext();
  const inDemo = useInDemo();
  const experimentOptions = useMemo(
    () => pick(experiment, "beta", "group"),
    [experiment]
  );

  return useMemo(() => {
    function fetch(
      mode: DataMode,
      context: string,
      word: string,
      type?: SuggestionType
    ) {
      const fetch = getFetchFn(
        actions,
        mode === Mode.RHYMES && !word ? Mode.AUTO : mode,
        type
      );

      fetch(context, word, {
        ...experimentOptions,
        demo: inDemo,
        diversity: song?.diversity,
        genre: song?.genre,
        filterProfanity: song?.filterProfanity,
        mood: song?.mood,
        metric: song?.metric,
      });
    }

    return { ...actions, fetch };
  }, [song, experimentOptions, inDemo, actions]);
}

export function useSuggestionsState<TMode extends DataMode>(
  mode: TMode,
  fn?: undefined,
  equalityFn?: EqualityFn<State[TMode]>
): State[TMode];

export function useSuggestionsState<TMode extends DataMode, TResult>(
  mode: TMode,
  fn: (state: State[TMode]) => TResult,
  equalityFn?: EqualityFn<TResult>
): TResult;

export function useSuggestionsState(
  mode: DataMode,
  fn?: (state: State[DataMode]) => unknown,
  equalityFn?: EqualityFn<unknown>
): unknown {
  return useSelector<State>(
    (state) => (fn ? fn(state[mode]) : state[mode]),
    equalityFn
  );
}

export function useSuggestionsData<TMode extends DataMode>(
  mode: TMode
): ModeStateData<TMode>;
export function useSuggestionsData<TMode extends DataMode, TResult>(
  mode: TMode,
  fn: (state: ModeStateData<TMode>) => TResult,
  equalityFn?: EqualityFn<unknown>
): TResult;

export function useSuggestionsData<TMode extends DataMode>(
  mode: TMode,
  fn?: (state: ModeStateData<TMode>) => ModeStateData<TMode>,
  equalityFn?: EqualityFn<unknown>
): ModeStateData<TMode> {
  return useSuggestionsState(
    mode,
    (state) => (fn ? fn(state.local) : state.local),
    equalityFn
  );
}

function extractThesaurusSuggestions(data: ThesaurusLocalState) {
  const synonyms = data.synonyms.items;
  const antonyms = data.antonyms.items;
  return [...synonyms, ...antonyms];
}

function extractInferenceSuggestions(
  data: LocalState,
  suggestionsLimit: number
) {
  const words = data.words.items.slice(0, suggestionsLimit);
  const lines = data.lines.items.slice(0, suggestionsLimit);
  return [...words, ...lines];
}

export function useSuggestionsSelectables(mode: DataMode) {
  const paywall = usePaywall();

  return useSuggestionsData(
    mode,
    (data) => {
      const suggestions =
        mode === Mode.THESAURUS
          ? extractThesaurusSuggestions(data as ThesaurusLocalState)
          : extractInferenceSuggestions(
              data as LocalState,
              paywall.suggestionsLimit
            );
      const selectables = suggestions.map((item) => item.minified);
      return selectables.length > 0 ? selectables : Constants.EMPTY_ARRAY;
    },
    isEqual
  );
}

export type SuggestionStatuses = Record<
  DataMode,
  { default: Status } & { [K in SuggestionType]?: Status }
>;

const STATUS_PRECEDENCE = ["loading", "failed", "succeeded", "idle"] as const;

/**
 * Returns the status considering the following order of precedence: "loading", "failed",
 * "succeeded", and "idle".
 */
function pickStatusByPrecedence(...statuses: Status[]): Status {
  const keys = statuses
    .map((status) => STATUS_PRECEDENCE.indexOf(status))
    .sort();
  return STATUS_PRECEDENCE[keys[0]];
}

export function useSuggestionsStatus() {
  return useSelector<State, SuggestionStatuses>(
    (state) => ({
      [Mode.AUTO]: {
        default: pickStatusByPrecedence(
          state.auto.remote.words.status,
          state.auto.remote.lines.status
        ),
        [SuggestionType.WORDS]: state.auto.remote.words.status,
        [SuggestionType.LINES]: state.auto.remote.lines.status,
      },
      [Mode.RHYMES]: {
        default: pickStatusByPrecedence(
          state.rhymes.remote.rhymeWords.status,
          state.rhymes.remote.rhymes.status
        ),
        [SuggestionType.WORDS]: state.rhymes.remote.rhymeWords.status,
        [SuggestionType.LINES]: state.rhymes.remote.rhymes.status,
      },
      [Mode.THESAURUS]: {
        default: state.thesaurus.remote.thesaurus.status,
      },
    }),
    shallowEqual
  );
}

export function useSuggestionsFailure() {
  return useSelector<State, [boolean, AxiosError[]]>(
    (state) => [
      pickStatusByPrecedence(
        state.auto.remote.words.status,
        state.auto.remote.lines.status,
        state.rhymes.remote.rhymeWords.status,
        state.rhymes.remote.rhymes.status,
        state.thesaurus.remote.thesaurus.status
      ) === "failed",
      [
        state.auto.remote.words.error,
        state.auto.remote.lines.error,
        state.rhymes.remote.rhymeWords.error,
        state.rhymes.remote.rhymes.error,
        state.thesaurus.remote.thesaurus.error,
      ].filter(Boolean) as AxiosError[],
    ],
    ([prevStatus, prevErrors], [currStatus, currErrors]) =>
      prevStatus === currStatus &&
      difference(prevErrors, currErrors).length === 0
  );
}
