import { useRouter } from "next/router";
import { useMemo } from "react";

/**
 * Read the query params.
 * This is needed because the router's query params doesn't have data on the application startup.
 */
function useQueryParams() {
  const router = useRouter();

  return useMemo(() => {
    const queryParamsStr = router.asPath.split("?").slice(1).join("");
    const urlSearchParams = new URLSearchParams(queryParamsStr);
    return Object.fromEntries(urlSearchParams.entries());
  }, [router.asPath]);
}

export default useQueryParams;
