import { useEffect } from "react";
import useTimeout from "../../hooks/use-timeout";
import { config } from "../../config";
import { useEffectOnce, useEvent } from "react-use";
import Constants from "../../constants";
import { useAnalyticsDispatch } from "../../data/analytics/hooks";
import { ActionType } from "../../data/analytics/actionTypes";
import * as Sentry from "@sentry/nextjs";
import { useAnalyticsContext } from "../../contexts/analytics-context";
import { AnalyticsEventPayload } from "../../utils/analytics-events-utils";
import { LifecycleEvent } from "../../types";
import { createAnalyticsActions } from "../../utils/analytics-utils";
import useDebounceCall from "../../hooks/use-debounce-call";
import { flush as flushFn } from "../../data/analytics/actions";
import { useExperimentsContext } from "../../contexts/experiments-context";

export default function AnalyticsResolver() {
  const [state, setState] = useAnalyticsContext();
  const dispatch = useAnalyticsDispatch();
  const [experiment] = useExperimentsContext();
  const { sessionId, lyricsId, analytics, task } = state.current;

  function initialize() {
    if (!analytics.actions) {
      setState({
        analytics: { ...analytics, actions: createAnalyticsActions() },
      });
    }
  }

  const flush = useDebounceCall(
    (reason: string) =>
      flushFn(dispatch)(sessionId!, reason, analytics, task, experiment.tag),
    100
  );

  const flushOnTimeout = () => flush(Constants.USER_INACTIVITY);
  const flushOnExit = () => flush.immediately(Constants.PAGE_EXIT);

  // Initializes the session on start up

  useEffectOnce(initialize);

  // Adds events

  useEvent(
    Constants.ANALYTICS_EVENT,
    (
      event: CustomEvent<{
        name: ActionType;
        payload: AnalyticsEventPayload;
        timestamp: string;
      }>
    ) => {
      const { name, payload, timestamp } = event.detail;
      let resolvedLyricsId = lyricsId;

      // Update the lyrics ID accordingly to opening and closing lyrics events
      if (name === ActionType.OPENED_LYRICS) {
        setState({ lyricsId: payload.song!.id });
        resolvedLyricsId = payload.song!.id;
      } else if (name === ActionType.CLOSED_LYRICS) {
        setState({ lyricsId: undefined });
      }

      dispatch({
        type: name,
        payload: { ...payload, lyricsId: resolvedLyricsId!, timestamp },
      });
    }
  );

  // Initializes the session if the user returns to the page

  function onPageStateChange(event: LifecycleEvent) {
    if (event.oldState === "hidden" && event.newState === "active") {
      initialize();
    }
  }

  useEffect(() => {
    // For some reason, lifecycle may not be available, which is imported in the _document.js file
    // https://sentry.io/share/issue/0237a121476144598ba51f3c29544e43/
    if (!global.lifecycle) {
      Sentry.captureMessage("lifecycle is not available", "warning");
      return;
    }

    global.lifecycle.addEventListener("statechange", onPageStateChange);

    return () =>
      global.lifecycle.removeEventListener("statechange", onPageStateChange);
  }, [analytics]);

  // Flushes the session after a given time of user inactivity

  useTimeout(flushOnTimeout, config.analyticsSessionExpirationTime, [
    analytics,
  ]);

  // Flushes the session when the page is closed

  useEffect(() => {
    if (analytics) {
      addEventListener("beforeunload", flushOnExit);
      return () => removeEventListener("beforeunload", flushOnExit);
    }
  }, [analytics]);

  return <></>;
}
