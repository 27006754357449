import {
  MUTATION_REPORT_SONG,
  MUTATION_UPDATE_SONG,
  QUERY_SONG,
  QUERY_SONGS,
} from "./graphql-operations";
import { serviceGraphqlClient } from "./graphql-client";
import {
  processUpdateSongResult,
  processUpdateSongVariables,
  UpdateSongResult,
} from "./graphql-processors";
import { RawSong, UpdateSongInput } from "../../types/song";
import { convertRawSongToSong } from "../../utils/editor-utils";
import { ApolloQueryResult, FetchResult } from "@apollo/client";

function handleError(
  result: ApolloQueryResult<unknown> | FetchResult<unknown>
) {
  if (result.errors?.length) {
    throw result.errors[0];
  }
}

// Songs

async function fetchSongById(songId: string) {
  const result = await serviceGraphqlClient.query<{ song: RawSong }>({
    query: QUERY_SONG,
    variables: { id: songId },
    fetchPolicy: "network-only",
  });

  handleError(result);

  return convertRawSongToSong(result.data.song);
}

async function updateSong(songId: string, data: UpdateSongInput) {
  const variables = processUpdateSongVariables(songId, data);
  const result = await serviceGraphqlClient.mutate<{
    updateSong: UpdateSongResult;
  }>({
    mutation: MUTATION_UPDATE_SONG,
    variables,
    errorPolicy: "all",
    refetchQueries: [{ query: QUERY_SONGS }],
  });

  handleError(result);

  return processUpdateSongResult(result.data!);
}

// Reports

async function reportSong(songId: string, reason: string) {
  const result = await serviceGraphqlClient.mutate({
    mutation: MUTATION_REPORT_SONG,
    variables: { data: { songId, reason } },
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  handleError(result);
}

export const ServiceGraphQL = {
  fetchSongById,
  updateSong,
  reportSong,
};
