import { useCallback } from "react";
import { reduce } from "./reducers";
import * as Sentry from "@sentry/nextjs";
import { useAnalyticsContext } from "../../contexts/analytics-context";
import { AnalyticsData } from "./types";
import { config } from "../../config";

/**
 * Hook that provides control functions of the session's analytical data.
 */
export function useAnalyticsDispatch(): AnalyticsData.Dispatch {
  const [state, setState] = useAnalyticsContext();

  /**
   * Adds a session event.
   */
  function dispatch(action: AnalyticsData.Action) {
    try {
      const analytics = reduce(state.current.analytics, action);
      setState({ analytics });
    } catch (error) {
      Sentry.captureException(error, { extra: action });

      // Suppress in production only
      if (config.env !== "production") {
        throw error;
      }
    }
  }

  return useCallback(dispatch, [setState]);
}
