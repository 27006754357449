import { ActionReducerMapBuilder, CaseReducer } from "@reduxjs/toolkit";

export function makeAddCaseFn<TState>(
  builder: ActionReducerMapBuilder<TState>
) {
  return <TPayload = unknown>(
    type: string,
    reducer: CaseReducer<TState, { type: string; payload: TPayload }>
  ) => builder.addCase(type, reducer);
}
