import { UpdateSongInput } from "../../types/song";
import { clearUndefined } from "../../utils/object-utils";

export type UpdateSongResult = { language: string | null; updatedAt: Date };

export function processUpdateSongResult(data: {
  updateSong: UpdateSongResult;
}): UpdateSongResult {
  return {
    language: data.updateSong.language,
    updatedAt: new Date(data.updateSong.updatedAt),
  };
}

export function processUpdateSongVariables(
  songId: string,
  data: UpdateSongInput
) {
  const content = data.content && JSON.stringify(data.content);

  return {
    id: songId,
    data: clearUndefined({ ...data, content }),
  };
}
