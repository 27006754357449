import { FC, ReactNode } from "react";
import { ParentProps } from "../types/props";

/**
 * Joins styles.
 */
export const join = (...styles: unknown[]): string => {
  return styles.filter(Boolean).join(" ");
};

/**
 * Checks if the code is runnning in the server side.
 */
export function isServerSide() {
  return !global.window;
}

/**
 * Checks if the code is runnning in the client side.
 */
export function isClientSide() {
  return !isServerSide();
}

/**
 * Runs the given function in the client side only.
 */
export function runOnClient<T>(fn: () => T): T | undefined {
  if (isClientSide()) {
    return fn();
  }
}

export function createToggleHandler<T extends Record<string, boolean>>(
  setter: (fn: (state: T) => T) => void,
  key: keyof T
) {
  return () => {
    setter((state: T): T => {
      const attribute = { [key]: !state[key] } as {
        [K in keyof T]: T[K];
      };
      return state ? { ...state, ...attribute } : attribute;
    });
  };
}

export function withProps<TOuter, TAll extends TOuter>(
  Component: FC<{ children: ReactNode } & TAll>,
  outerProps: TOuter
): FC<{ children: ReactNode } & Omit<TAll, keyof TOuter>> {
  return (innerProps) =>
    Component({ ...outerProps, ...innerProps } as {
      children: ReactNode;
    } & TAll);
}

export function combineProviders<TProps>(
  ...components: (
    | FC<{ children: ReactNode }>
    | [FC<{ children: ReactNode } & TProps>, TProps]
  )[]
): FC<{ children: ReactNode }> {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return function CombinedComponent(props: { children: ReactNode }) {
        if (Array.isArray(CurrentComponent)) {
          const [ResolvedCurrentComponent, resolvedProps] = CurrentComponent;
          const allProps = { ...props, ...resolvedProps };

          return (
            <AccumulatedComponents>
              <ResolvedCurrentComponent {...allProps} />
            </AccumulatedComponents>
          );
        }

        return (
          <AccumulatedComponents>
            <CurrentComponent
              {...(props as { children: ReactNode } & Partial<TProps>)}
            />
          </AccumulatedComponents>
        );
      };
    },
    ({ children }: { children: ReactNode }) => <>{children}</>
  );
}

export function replaceTokens<T extends string>(
  string: string,
  tokenToNodeMap: Record<T, ReactNode>,
  tokensToCheck?: T[]
): ReactNode {
  if (!tokensToCheck) {
    return replaceTokens(
      string,
      tokenToNodeMap,
      Object.keys(tokenToNodeMap) as T[]
    );
  }

  if (!string || tokensToCheck.length === 0) {
    return string;
  }

  const token = tokensToCheck[0];
  const rest = tokensToCheck.slice(1);
  const parts = string.split(token);

  if (parts.length === 1) {
    return replaceTokens(string, tokenToNodeMap, rest);
  }

  const before = replaceTokens(parts[0], tokenToNodeMap, rest);
  const node = tokenToNodeMap[token];
  const after = replaceTokens(parts[1], tokenToNodeMap, rest);

  return (
    <>
      {before}
      {node}
      {after}
    </>
  );
}

function _decorateText(
  text: string,
  token: string,
  decorators: FC<ParentProps> | FC<ParentProps>[]
) {
  return text.split(token).map((part, index) => {
    if (index % 2 === 0) {
      return part;
    }

    const Decorator = Array.isArray(decorators)
      ? decorators[Math.floor(index / 2)]
      : decorators;

    return Decorator ? <Decorator>{part}</Decorator> : part;
  });
}

export function decorateText(
  ...args:
    | [text: string, decorators: FC<ParentProps> | FC<ParentProps>[]]
    | [
        text: string,
        token: string,
        decorators: FC<ParentProps> | FC<ParentProps>[]
      ]
) {
  if (args.length === 2) {
    const [text, decorators] = args;
    return _decorateText(text, "*", decorators);
  }

  if (args.length === 3) {
    const [text, token, decorators] = args;
    return _decorateText(text, token, decorators);
  }
}
