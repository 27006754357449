import "bootstrap/dist/css/bootstrap.min.css";
import "draft-js/dist/Draft.css";
import "../polyfills";
import "../styles/index.scss";

import type { AppProps, NextWebVitalsMetric } from "next/app";
import { SettingsContextProvider } from "../contexts/settings-context";
import { config } from "../config";
import AnalyticsResolver from "../components/analytics/AnalyticsResolver";
import useQueryParams from "../hooks/use-query-params";
import { ApolloProvider } from "@apollo/client";
import { SuggestionsProvider } from "../data/suggestions";
import { useEffectOnce } from "react-use";
import { ErrorHandler } from "../components/ErrorHandler";
import { serviceGraphqlClient } from "../api/service";
import * as Sentry from "@sentry/nextjs";
import { ExperimentsContextProvider } from "../contexts/experiments-context";
import { UserContextProvider } from "../contexts/user-context";
import { AnalyticsContextProvider } from "../contexts/analytics-context";
import { useRouter } from "next/router";
import { ParentArgsContextProvider } from "../contexts/parent-args-context";
import { StyledEngineProvider } from "@mui/material";
import { combineProviders, withProps } from "../utils/next-utils";
import { checkIsInsideIFrame } from "../utils/browser-utils";
import { useEffect } from "react";
import { log } from "../utils/debug";
import { OnboardingProvider } from "../contexts/onboarding-context";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import defaultLang from "../assets/localization/languages/en/common.json";

i18n.load({ en: defaultLang });
i18n.activate("en");

const Providers = combineProviders(
  withProps(StyledEngineProvider, { injectFirst: true }),
  withProps(I18nProvider, { i18n }),
  withProps(ApolloProvider, { client: serviceGraphqlClient }),
  ParentArgsContextProvider,
  ExperimentsContextProvider,
  UserContextProvider,
  AnalyticsContextProvider,
  SettingsContextProvider,
  SuggestionsProvider,
  OnboardingProvider
);

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (config.env !== "production") {
    log(metric);
  }
}

export default function MyApp({ Component, pageProps }: AppProps) {
  const { id: userId = undefined } = useQueryParams();
  const router = useRouter();

  useEffectOnce(() => {
    if (
      router.route !== "/direct-access-not-allowed" &&
      !config.allowDirectAccess &&
      !checkIsInsideIFrame()
    ) {
      router.replace(`/direct-access-not-allowed`);
    }
  });

  useEffect(() => {
    if (userId) {
      const locale =
        navigator.languages?.[0] ||
        navigator.userLanguage ||
        navigator.language ||
        navigator.browserLanguage ||
        "unset";

      Sentry.setUser({ id: userId, locale });

      if (config.hotjarEnabled) {
        global.window?.hj?.("identify", userId, { locale });
      }
    }
  }, [userId]);

  useEffect(() => {
    i18n.activate(global.window.navigator.language);
  }, []);

  // This route is controlled by an environment variable, which is disabled in production
  if (router.route === "/auth") {
    return (
      <>
        <ErrorHandler />
        <Component {...pageProps} />
      </>
    );
  }

  return (
    <>
      <Providers>
        <AnalyticsResolver />
        <ErrorHandler />
        <Component {...pageProps} />
      </Providers>
    </>
  );
}
