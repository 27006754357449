import { calculateDurationInSeconds } from "../../../utils/time-utils";
import { AnalyticsData } from "../types";
import { reduceActions } from "./actions-reducer";
import { reduceEvents } from "./events-reducer";

function calculateEventsSize(events: AnalyticsData.Event[]): number {
  return events.length ? JSON.stringify(events).length : 0;
}

function updateTime(
  actions: AnalyticsData.Actions | null,
  timestamp?: string
): AnalyticsData.Actions | null {
  if (!actions || !timestamp) {
    return actions;
  }

  const sessionDuration = calculateDurationInSeconds(
    actions.timestamp_session_start,
    timestamp
  );

  return {
    ...actions,
    timestamp_session_end: timestamp,
    session_duration: sessionDuration,
  };
}

export function reduce(
  state: AnalyticsData.State,
  action: AnalyticsData.Action
): AnalyticsData.State {
  const outdatedActions = reduceActions(
    state.actions,
    action as AnalyticsData.EventsAction
  );
  const actions = updateTime(outdatedActions, action.payload.timestamp);

  const events = reduceEvents(
    state.events,
    action as AnalyticsData.EventsAction
  );

  // May be a heavy operation - this can be done by calculating the size of the added event only
  const eventsSize =
    events === state.events ? state.eventsSize : calculateEventsSize(events);

  return { actions, events, eventsSize };
}
