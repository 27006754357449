import { identity } from "lodash";
import {
  isRhymesResult,
  isRhymeWordsResult,
  isThesaurusResult,
} from "../../api/inference";
import { SelectionType } from "../../constants/selection-type";
import { Mode } from "../../types/mode";
import { SuggestionType } from "../../types/suggestion-type";
import { AnalyticsEventPayload } from "../../utils/analytics-events-utils";
import { pushUniqueSorted } from "../../utils/array-utils";
import { AnalyticsData } from "./types";

/**
 * Checks if the response for a rhymes, rhyme words, or thesaurus requests are empty.
 * This checks only requests that have a word as param.
 */
export function hasEmptyResponse(payload: AnalyticsEventPayload): boolean {
  const { mode, response } = payload;

  if (isRhymesResult(mode!, response!)) {
    return !response.data.words?.length || !response.data.lines?.length;
  }

  if (isRhymeWordsResult(mode!, response!)) {
    return !response.data.length;
  }

  if (isThesaurusResult(mode!, response!)) {
    return (
      !response.data.definition?.length ||
      !response.data.synonym?.length ||
      !response.data.antonym?.length
    );
  }

  return false;
}

/**
 * Add word param to empty request array if the response is empty.
 */
export function resolveEmptyResponse(
  state: string[],
  payload: AnalyticsEventPayload
): string[] {
  if (payload.mode === Mode.AUTO || !hasEmptyResponse(payload)) {
    return state;
  }

  const word =
    payload.response!.params.word || payload.response!.params["rhyme-word"]!;

  return pushUniqueSorted(state, word);
}

// Methods to build actions keys. These are used to assign a value to an attribute in the analytics
// actions during the events reducer processing

export function buildSuggestionSelectionActionsKey(
  mode: Mode,
  suggestionType: SuggestionType,
  selectionType: SelectionType
): keyof AnalyticsData.ActionsSelection {
  const key =
    mode === Mode.THESAURUS
      ? `${selectionType}_${suggestionType}`
      : `${selectionType}_${suggestionType}_${mode}`;
  return key as keyof AnalyticsData.ActionsSelection;
}

export function buildApiRequestActionsKey(
  mode: Mode
): keyof AnalyticsData.ActionsApiRequests {
  const key = `api_requests_${mode}`;
  return key as keyof AnalyticsData.ActionsApiRequests;
}

export function buildEmptyResponseActionsKey(
  mode: Mode
): keyof AnalyticsData.ActionsEmptyRequests {
  const key = `words_empty_requests_${mode}`;
  return key as keyof AnalyticsData.ActionsEmptyRequests;
}

export function getSharingLabel(settings: { public?: boolean }) {
  return settings.public ? "public" : "disabled";
}

export function buildReducer<TState, TResult = TState>(
  map: AnalyticsData.ReducerMap<TState, TResult>
) {
  return (state: TState, action: AnalyticsData.EventsAction) => {
    const reduce = map[action.type] || identity;
    return reduce(state, action.payload) as TResult;
  };
}
