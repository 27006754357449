/**
 * Round a number keeping decimal places.
 * @param {number} value value to round
 * @param {number} nPlaces number of decimal places to keep - defaults to 1
 * @returns {number} result
 */
export function roundFixed(value, nPlaces = 1) {
  const base = 10 ** nPlaces;
  return Math.round(value * base) / base;
}
