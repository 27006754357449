import { Descendant, Editor, Node, NodeEntry, Path, Point, Range } from "slate";
import { AnySongContent } from "../types/song";
import { checkIsDraftJsContent } from "./editor-utils";
import { ReactEditor } from "slate-react";

export const EMPTY_CONTENT: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

/**
 * Returns the anchor and focus ordered from left to right.
 */
export function getOrderedPoints(selection: Range) {
  const start = Point.isBefore(selection.anchor, selection.focus)
    ? selection.anchor
    : selection.focus;
  return [
    start,
    start === selection.anchor ? selection.focus : selection.anchor,
  ];
}

/**
 * Get selection range on the given node. Useful to apply selection/caret decoration.
 */
export function getNodeSelectionRange(
  [node, path]: NodeEntry,
  selection: Range
): Range | undefined {
  const [start, end] = getOrderedPoints(selection);
  const toStart = Path.compare(path, start.path);
  const toEnd = Path.compare(path, end.path);
  const length = Node.string(node).length;

  if (toStart === 0 && toEnd < 0) {
    return {
      anchor: start,
      focus: { path, offset: length },
    };
  }

  if (toStart === 0 && toEnd === 0) {
    return {
      anchor: start,
      focus: end,
    };
  }

  if (toStart > 0 && toEnd < 0) {
    return {
      anchor: { path, offset: 0 },
      focus: { path, offset: length },
    };
  }

  if (toStart > 0 && toEnd === 0) {
    return {
      anchor: { path, offset: 0 },
      focus: end,
    };
  }

  return undefined;
}

export function extractLinesFromContent(content: Descendant[]): string[] {
  return content.map(Node.string);
}

export function extractTextFromEditor(editor: Editor) {
  return extractLinesFromContent(editor.children).join("\n");
}

export function convertContentToShortText(content: Descendant[] | null) {
  if (!content) {
    return "";
  }

  return content.map(Node.string).filter(Boolean).slice(0, 4).join(" / ");
}

export function ensureSlateContent(
  content: AnySongContent | null
): Descendant[] | null {
  if (!content) {
    return null;
  }

  if (!checkIsDraftJsContent(content)) {
    return content;
  }

  return content.blocks.map(({ text }) => ({
    type: "paragraph",
    children: [{ text }],
  }));
}

export function focusEditor(editor: Editor) {
  if (editor.selection) {
    const domRange = ReactEditor.toDOMRange(editor, editor.selection);
    const domSelection = window.getSelection();

    if (domSelection) {
      domSelection.removeAllRanges();
      domSelection.addRange(domRange);
    }

    const editorEl = ReactEditor.toDOMNode(editor, editor);

    editorEl.focus();
  } else {
    ReactEditor.focus(editor);
  }
}

export function extractTextFromSelection(editor: Editor) {
  const { selection } = editor;

  if (!selection || Range.isCollapsed(selection)) {
    return "";
  }

  const start = Range.start(selection);
  const end = Range.end(selection);
  const isSingleLine = Path.equals(start.path, end.path);

  // Can use Editor.string() only in this case because it doesn't support multi-line selection
  if (isSingleLine) {
    return Editor.string(editor, selection);
  }

  // Workaround for multi-line selection
  const nodes = Node.texts(editor, { from: start.path, to: end.path });
  const lines = Array.from(nodes).map(([node, path]) => {
    const text = Node.string(node);

    if (Path.equals(path, start.path)) {
      return text.substring(start.offset);
    }

    if (Path.equals(path, end.path)) {
      return text.substring(0, end.offset);
    }

    return text;
  });

  return lines.join("\n");
}
