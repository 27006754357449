import { syllable } from "syllable";
import Constants from "../constants";
import { withSum } from "./array-utils";

export function fixICases(text: string) {
  return text
    .replace(/\bi\b(?!')/gi, "I")
    .replace(/\bi\b(?='\bm\b|'\bve\b|'\bll\b)/gi, "I")
    .replace(/\bim\b/gi, "I'm");
}

export const isWhitespace = (char: string) => /\s/.test(char);

/**
 * Gets the last word from a string.
 */
export function getLastWord(text: string) {
  return /\b[^\d\W]+\b(?!.*\b[^\d\W]+\b)/s.exec(text)?.[0] || "";
}

/**
 * Extracts the context from the editor based on the caret position.
 */
export function formatContext(content: string) {
  const lines = content
    .trimStart()
    // Maximum of 2 line breaks
    .replace(/\s*\n\s*\n\s*/, "\n\n")
    .split("\n")
    .map((content) => content.trimStart());

  const offset = Math.max(lines.length - Constants.MAX_CONTEXT_LINES, 0);

  return lines.slice(offset).join("\n");
}

const ENDS_WITH_LINE_BREAK_REGEX = /\n\s*$/;

export function endsWithLineBreak(text?: string) {
  return ENDS_WITH_LINE_BREAK_REGEX.test(text || "");
}

export function extractNewText(context: string, previousContext: string) {
  // Context ends with a line break while the previous ends in a line with text
  if (endsWithLineBreak(context) && !endsWithLineBreak(previousContext)) {
    return null;
  }

  const trimmedContext = context.trimEnd();
  const trimmedPreviousContext = previousContext.trimEnd();

  // Something from the previous context was erased
  if (!trimmedContext.startsWith(trimmedPreviousContext)) {
    return null;
  }

  const newText = context.substring(previousContext.length);
  const onlySpaceAndLineBreaks = !newText.trim();
  const lineBreakBetweenText = /\w\n\w/.test(newText);

  return onlySpaceAndLineBreaks ||
    lineBreakBetweenText ||
    endsWithLineBreak(newText)
    ? null
    : newText.trimStart().replace(/\s+/g, " ").toLowerCase();
}

/**
 * Returns whether any suggestion contains the text or not.
 */
export function isMatchingSuggestion(text: string, suggestions: string[]) {
  const hasEndedWithWhitespace = endsWithSpace(text);

  // Check if text added after the previous context is included in the beginning of a suggestion
  return suggestions.some((suggestion) => {
    return suggestion === text.trimEnd()
      ? !hasEndedWithWhitespace
      : suggestion.startsWith(text);
  });
}

const ENDS_WITH_SPACE_REGEX = /\s$/s;

export function endsWithSpace(text: string) {
  return ENDS_WITH_SPACE_REGEX.test(text);
}

export function countLineSyllables(text: string) {
  return text
    .split(/[^a-z']/gi)
    .filter(Boolean)
    .map(syllable)
    .reduce(withSum, 0);
}

export function minifyText<T extends string | undefined>(
  text: T,
  { ignoreSpace = true } = {}
): T {
  if (!text) {
    return text;
  }

  const normalized = text.normalize("NFD").toLowerCase();

  if (ignoreSpace) {
    return normalized.replace(/[^a-z0-9]/g, "") as T;
  }

  return normalized.replace(/\s+/g, " ").replace(/[^a-z0-9 ]/g, "") as T;
}

export function minifyTextForSearching<T extends string | undefined>(
  text: T
): T {
  return minifyText(text, { ignoreSpace: true });
}

function getWrittenIndex(text: string, writing: string): number {
  let textIndex = 0;
  let selectingIndex = 0;

  while (textIndex < text.length && /\s/.test(text[textIndex])) {
    textIndex += 1;
  }

  while (
    textIndex < text.length &&
    selectingIndex < writing.length &&
    text[textIndex].toLowerCase() === writing[selectingIndex]
  ) {
    textIndex += 1;
    selectingIndex += 1;
  }

  return textIndex;
}

function cut(text: string, begin: number, end: number) {
  return text.substring(
    Math.min(begin, Constants.SUGGESTION_MAX_LENGTH),
    Math.min(end, Constants.SUGGESTION_MAX_LENGTH)
  );
}

/**
 * Extract each piece of the suggestion text on the box to be styled separately.
 */
export function extractTextSelectionPieces(
  text: string,
  written: string
): [
  writtenText: string,
  missingText: string,
  lastWord: string,
  ellipis: string,
  rest: string
] {
  const selectingCurrentSuggestion =
    written && text.trim().toLowerCase().startsWith(written);
  const writtenIndex = selectingCurrentSuggestion
    ? getWrittenIndex(text, written)
    : -1;
  const lastSpaceIndex =
    text.lastIndexOf(" ", Constants.SUGGESTION_MAX_LENGTH) + 1;

  const writtenTextBegin = text.startsWith("\n") ? 1 : 0;
  const writtenTextEnd = writtenIndex;
  const missingTextBegin = writtenIndex;
  const missingTextEnd = text.length;
  const lastWordBegin = lastSpaceIndex;
  const lastWordEnd = text.length;

  const writtenText = cut(text, writtenTextBegin, writtenTextEnd);
  const missingText = cut(
    text,
    Math.min(missingTextBegin, lastWordBegin),
    Math.min(missingTextEnd, lastWordBegin)
  );
  const lastWord = cut(
    text,
    Math.max(lastWordBegin, writtenTextEnd),
    Math.max(lastWordEnd, writtenTextEnd)
  );
  const ellipsis = text.length > 60 ? "..." : "";
  const rest = text.substring(missingTextBegin);

  return [writtenText, missingText, lastWord, ellipsis, rest];
}

/**
 * Hides a string content keeping its form.
 */
export function obscureText(text: string): string {
  return text.replace(/[a-zA-Z]/g, () => {
    const randomLetter = Math.floor(Math.random() * 26 + "a".charCodeAt(0));
    return String.fromCharCode(randomLetter);
  });
}
