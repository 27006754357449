export type Mood = (typeof MOODS)[keyof typeof MOODS];

const STRING_KEYS = {
  angry: "mood_angry",
  blessed: "mood_blessed",
  confused: "mood_confused",
  happy: "mood_happy",
  heartbroken: "mood_heartbroken",
  inLove: "mood_love",
  proud: "mood_proud",
  sad: "mood_sad",
  sexy: "mood_sexy",
};

export const MOODS = {
  angry: { name: STRING_KEYS.angry, emoji: "😡", value: "angry" },
  blessed: { name: STRING_KEYS.blessed, emoji: "🙏", value: "spiritual" },
  confused: { name: STRING_KEYS.confused, emoji: "🤔", value: "confused" },
  happy: { name: STRING_KEYS.happy, emoji: "😀", value: "happy" },
  heartbroken: {
    name: STRING_KEYS.heartbroken,
    emoji: "💔",
    value: "heartbroken",
  },
  inLove: { name: STRING_KEYS.inLove, emoji: "❤️", value: "in_love" },
  neutral: { name: "", emoji: "🎭", value: null },
  proud: { name: STRING_KEYS.proud, emoji: "✊", value: "proud" },
  sad: { name: STRING_KEYS.sad, emoji: "😔", value: "sad" },
  sexy: { name: STRING_KEYS.sexy, emoji: "🌶️", value: "sexy" },
} as const;

export const MOODS_LIST = Object.values(MOODS);
