import { MutableRefObject, useRef } from "react";

export function useRefOf<T>(initiator: () => T): MutableRefObject<T> {
  const ref = useRef<T>();

  if (!ref.current) {
    ref.current = initiator();
  }

  return ref as MutableRefObject<T>;
}
