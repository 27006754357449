import { join } from "../../utils/next-utils";
import { Button, ButtonProps } from "@mui/material";
import styles from "./CustomButton.module.scss";

type CustomButtonProps = Omit<ButtonProps, "variant" | "color"> & {
  variant?: keyof typeof VARIANT_TO_STYLE_MAP;
  color?: keyof typeof COLOR_TO_STYLE_MAP;
};

const VARIANT_TO_STYLE_MAP = {
  text: styles.text,
  contained: styles.contained,
  outlined: styles.outlined,
};

const COLOR_TO_STYLE_MAP = {
  primary: styles.primary,
  secondary: styles.secondary,
  error: styles.error,
  contrast: styles.contrast,
};

/**
 * @param {import("@mui/material").ButtonProps} props
 */
export function CustomButton({
  className,
  variant,
  color,
  children,
  disabled,
  ...props
}: CustomButtonProps) {
  return (
    <Button
      className={join(
        styles.root,
        VARIANT_TO_STYLE_MAP[variant || "contained"],
        COLOR_TO_STYLE_MAP[color || "primary"],
        disabled && styles.disabled,
        className
      )}
      variant={variant || "contained"}
      disabled={disabled}
      {...props}
    >
      <label>{children}</label>
    </Button>
  );
}
