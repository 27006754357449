import { configureStore } from "@reduxjs/toolkit";
import { Middleware, PreloadedState } from "redux";
import createSagaMiddleware from "redux-saga";
import { config } from "../../config";
import { reducer } from "./reducers";
import { saga } from "./sagas";
import { State } from "./types";

const LOGGER_ENABLED = false;

const logger: Middleware<object, State> = (store) => (next) => (action) => {
  if (config.env !== "test" || !LOGGER_ENABLED) {
    return next(action);
  }

  console.group(action.type);
  console.info("dispatching", action);
  const result = next(action);
  console.log("next state", store.getState());
  console.groupEnd();
  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createStore(preloadedState?: PreloadedState<any>) {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware).concat(logger),
    devTools: config.env !== "production",
    preloadedState,
  });
  sagaMiddleware.run(saga);
  return store;
}
