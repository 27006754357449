import { createContext, useContext, useRef } from "react";
import { StartUpTasks } from "../constants/start-up-tasks";
import useQueryParams from "../hooks/use-query-params";
import { ParentProps } from "../types/props";
import {
  DEFAULT_FEATURE_FLAGS,
  FeatureFlags,
  extractFeatureFlags,
} from "../utils/feature-flags-utils";

type ParentArgsContextValue = {
  userId: string;
  featureFlags: FeatureFlags;
  task?: StartUpTasks.Any;
};

const featureFlags = DEFAULT_FEATURE_FLAGS;

const ParentArgsContext = createContext<ParentArgsContextValue>({
  userId: "",
  featureFlags: DEFAULT_FEATURE_FLAGS,
});

export function useParentArgsContext() {
  return useContext(ParentArgsContext);
}

export function ParentArgsContextProvider({ children }: ParentProps) {
  const query = useQueryParams();
  const userId = useRef(query.id).current;
  const task =
    (useRef(query.args).current as StartUpTasks.Any | "") || undefined;

  if (query.featureFlags && featureFlags === DEFAULT_FEATURE_FLAGS) {
    Object.assign(featureFlags, extractFeatureFlags(query.featureFlags));
  }

  return (
    <ParentArgsContext.Provider value={{ userId, featureFlags, task }}>
      {children}
    </ParentArgsContext.Provider>
  );
}
