import { AllOptions } from "../../api/inference";
import { formatContext } from "../../utils/text-utils";
import { ActionTypes } from "./action-types";
import { FetchAction, ThesaurusOptions } from "./types";

function createActionCreator<
  TOptions extends AllOptions | ThesaurusOptions = AllOptions
>(type: ActionTypes) {
  return (
    context: string,
    word: string,
    options: TOptions
  ): FetchAction<typeof type, TOptions> => {
    return {
      type,
      payload: {
        word,
        context: formatContext(context),
        options,
      },
    };
  };
}

export const fetchAuto = createActionCreator(ActionTypes.FETCH_AUTO);

export const fetchAutoWords = createActionCreator(ActionTypes.FETCH_AUTO_WORDS);

export const fetchAutoLines = createActionCreator(ActionTypes.FETCH_AUTO_LINES);

export const fetchRhymes = createActionCreator(ActionTypes.FETCH_RHYMES);

export const fetchRhymeWords = createActionCreator(
  ActionTypes.FETCH_RHYME_WORDS
);

export const fetchRhymeLines = createActionCreator(
  ActionTypes.FETCH_RHYME_LINES
);

export const fetchThesaurus = createActionCreator<ThesaurusOptions>(
  ActionTypes.FETCH_THESAURUS
);

export function clear() {
  return { type: ActionTypes.CLEAR };
}

export function clearAuto(keepStatus?: boolean) {
  return { type: ActionTypes.CLEAR_AUTO, payload: { keepStatus } };
}

export function clearRhymes(keepStatus?: boolean) {
  return { type: ActionTypes.CLEAR_RHYMES, payload: { keepStatus } };
}

export function clearThesaurus(keepStatus?: boolean) {
  return { type: ActionTypes.CLEAR_THESAURUS, payload: { keepStatus } };
}
