export const byUnique = <T>(item: T, index: number, array: T[]) =>
  array.indexOf(item) === index;

/**
 * Adds a value to the array if not already present.
 */
export function pushUniqueSorted(array: string[], value: string) {
  return array.includes(value) ? array : [...array, value].sort();
}

export function ensureArray(value: null | undefined): never[];
export function ensureArray<T>(value: T[]): T[];
export function ensureArray<T>(value: T): T[];

export function ensureArray(value: unknown): unknown[] {
  if (value === null || value === undefined) {
    return [];
  }

  return Array.isArray(value) ? value : [value];
}

/**
 * Reducer that sums.
 */
export const withSum = (result: number, value: number) => result + value;

/**
 * Remove value at given index from array. This isn't in-place.
 */
export function removeIndex<T>(array: T[], index: number): T[] {
  const copy = [...array];
  copy.splice(index, 1);
  return copy;
}

/**
 * Two arrays are equal if their reference are the same of if they are both empty.
 */
export function arrayEquals(a: unknown[], b: unknown[]): boolean {
  const equals = a === b;
  const areEmpty = a.length === 0 && b.length === 0;

  // Consider empty arrays as equal
  return equals || areEmpty;
}
