import { noop } from "lodash";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useUpdateUser } from "../api/service";
import Constants from "../constants";
import { ParentProps } from "../types/props";
import { useUserContext } from "./user-context";

const STRING_KEYS = {
  moodTitle: "song_mood",
  moodDescription: "set_mood",
  thesaurusTitle: "thesaurus_title",
  thesaurusDescription: "instantly_find",
  hideTitle: "hide_button",
  hideDescription: "assistant_message",
};

export type OnboardingStep = (typeof STEPS)[number];

export type OnboardingTarget = OnboardingStep["target"];

export const STEPS = [
  {
    title: STRING_KEYS.moodTitle,
    description: STRING_KEYS.moodDescription,
    target: "mood",
  },
  {
    title: STRING_KEYS.thesaurusTitle,
    description: STRING_KEYS.thesaurusDescription,
    target: "thesaurus",
  },
  {
    title: STRING_KEYS.hideTitle,
    description: STRING_KEYS.hideDescription,
    target: "hide",
  },
] as const;

export type OnboardingValue = {
  title: string;
  description: string;
  target: "mood" | "thesaurus" | "hide";
};

export type OnboardingContextValue = {
  current: number;
  onChangeStep(current: number): void;
  onClose(): void;
  onDialogReady(ready: boolean): void;
};

export const DEFAULT_ONBOARDING_CONTEXT_VALUE: OnboardingContextValue = {
  current: -1,
  onChangeStep: noop,
  onClose: noop,
  onDialogReady: noop,
};

export const OnboardingContext = createContext<OnboardingContextValue>(
  DEFAULT_ONBOARDING_CONTEXT_VALUE
);

export function useOnboardingContext() {
  return useContext(OnboardingContext);
}

export function OnboardingProvider({ children }: ParentProps) {
  /**
   * Holds the onboarding step.
   * - null means that the user information hasn't arrived yet
   * - -1 means that the user has dismissed the onboarding
   * - 0, 1, and 2 means the step in which the user is currently in
   * - 3 means that the user has completed the onboarding
   */
  const [current, setCurrent] = useState<number | null>(null);
  const [user] = useUserContext();
  const [, updateOnboarding] = useUpdateUser();
  const [shouldDisplay, setShouldDisplay] = useState(false);

  const onClose = () => setCurrent(-1);

  const onDialogReady = (ready: boolean) => {
    if (ready) {
      // Delays the displaying of the onboarding dialog for 3 seconds
      // This is been controlled here because the highlighting of features also depends on it
      setTimeout(() => setShouldDisplay(true), Constants.ONBOARDING_DELAY);
    } else {
      setShouldDisplay(false);
    }
  };

  const value = useMemo((): OnboardingContextValue => {
    const resolvedCurrent = shouldDisplay ? current ?? -1 : -1;

    return {
      current: resolvedCurrent,
      onChangeStep: setCurrent,
      onClose,
      onDialogReady,
    };
  }, [current, shouldDisplay]);

  useEffect(() => {
    if (
      current === null &&
      user &&
      user.onboardingStep >= 0 &&
      user.onboardingStep < STEPS.length
    ) {
      setCurrent(user.onboardingStep);
    }
  }, [user]);

  useEffect(() => {
    if (current !== null) {
      updateOnboarding(current);
    }
  }, [current]);

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
}
