import { config } from "../../config";
import axios from "axios";
import { Author } from "../../data/public-song/types";
import { RawSong } from "../../types/song";

const serviceApiClient = axios.create({
  baseURL: config.serviceApiHost,
  withCredentials: true,
});

async function getSong(id: string): Promise<{ song: RawSong; author: Author }> {
  const response = await serviceApiClient.get(`/songs/${id}`);
  return response.data;
}

export const ServiceApi = { getSong };
