import axios from "axios";
import { takeLatest, call, all, put } from "redux-saga/effects";
import InferenceApi from "../../api/inference/rest-api";
import { addRequestedSuggestionsEvent } from "../../utils/analytics-events-utils";
import { uuid } from "../../utils/crypto-utils";
import { ActionTypes } from "./action-types";
import * as Sentry from "@sentry/nextjs";
import { Mode } from "../../types/mode";
import { clearAuto, clearRhymes, clearThesaurus } from "./actions";
import {
  failAutoLines,
  failAutoWords,
  failRhymeLines,
  failRhymes,
  failThesaurus,
  loadAutoLines,
  loadAutoWords,
  loadRhymeLines,
  loadRhymes,
  loadThesaurus,
  succeedAutoLines,
  succeedAutoWords,
  succeedRhymeLines,
  succeedRhymes,
  succeedThesaurus,
} from "./saga-actions";
import { AllOptions, InferenceApiResult } from "../../api/inference";
import { AnyFunction } from "../../types/common";
import { FetchAction, ThesaurusOptions } from "./types";

type AutoSagaOptions = {
  cleared?: boolean;
  callId?: string;
};

/**
 * Supresses the errors from the call and automatically reports to Sentry.
 */
export function supress<T extends AnyFunction>(fn: T, ...args: Parameters<T>) {
  const supressedFn = async (...args: Parameters<T>) => {
    try {
      return { result: await fn(...args) };
    } catch (error) {
      Sentry.captureException(error);

      if (!axios.isAxiosError(error)) {
        throw error;
      }

      return { error };
    }
  };

  return call(supressedFn, ...args);
}

export function* addEvents(
  callId: string,
  mode: Mode,
  loadedMore: boolean,
  responses: InferenceApiResult<unknown>[]
) {
  yield all(
    responses
      .filter(Boolean)
      .map((response) =>
        call(addRequestedSuggestionsEvent, callId, mode, response, loadedMore)
      )
  );
}

export function* fetchAutoWordsAsync(
  { payload }: FetchAction<ActionTypes.FETCH_AUTO_WORDS, AllOptions>,
  sagaOptions: AutoSagaOptions = {}
) {
  const { context, options } = payload;
  const { cleared, callId = uuid() } = sagaOptions;

  yield put(loadAutoWords(cleared));

  const { result = null, error } = yield supress(
    InferenceApi.fetchWords,
    context,
    options
  );

  if (result) {
    yield put(succeedAutoWords(callId, result));
    yield addEvents(callId, Mode.AUTO, !cleared, [result]);
  } else {
    yield put(failAutoWords(error));
  }
}

export function* fetchAutoLinesAsync(
  { payload }: FetchAction<ActionTypes.FETCH_AUTO_LINES, AllOptions>,
  sagaOptions: AutoSagaOptions = {}
) {
  const { context, options } = payload;
  const { cleared, callId = uuid() } = sagaOptions;

  yield put(loadAutoLines(cleared));

  const { result = null, error } = yield supress(
    InferenceApi.fetchLines,
    context,
    options
  );

  if (result) {
    yield put(succeedAutoLines(callId, result));
    yield addEvents(callId, Mode.AUTO, !cleared, [result]);
  } else {
    yield put(failAutoLines(error));
  }
}

export function* fetchAutoAsync(
  action: FetchAction<ActionTypes.FETCH_AUTO, AllOptions>
) {
  const sagaOptions = { cleared: true, callId: uuid() };

  yield all([
    fetchAutoWordsAsync(
      { ...action, type: ActionTypes.FETCH_AUTO_WORDS },
      sagaOptions
    ),
    fetchAutoLinesAsync(
      { ...action, type: ActionTypes.FETCH_AUTO_LINES },
      sagaOptions
    ),
  ]);
}

export function* fetchRhymesAsync({
  payload,
}: FetchAction<ActionTypes.FETCH_RHYMES, AllOptions>) {
  const { word, context, options } = payload;
  const callId = uuid();

  yield put(loadRhymes(word));

  const [
    { result: rhymeWords = null, error: rhymeWordsError },
    { result: rhymes = null, error: rhymesError },
  ] = yield all([
    supress(InferenceApi.fetchRhymeWords, word, context, options),
    supress(InferenceApi.fetchRhymes, word, context, options),
  ]);

  if (rhymes || rhymeWords) {
    yield put(succeedRhymes(callId, rhymeWords, rhymes));
    yield addEvents(callId, Mode.RHYMES, false, [rhymeWords, rhymes]);
  } else {
    yield put(failRhymes(rhymeWordsError || rhymesError));
  }
}

export function* fetchRhymeLinesAsync({
  payload,
}: FetchAction<ActionTypes.FETCH_RHYMES, AllOptions>) {
  const { word, context, options } = payload;
  const callId = uuid();

  yield put(loadRhymeLines());

  const [{ result = null, error }] = yield all([
    supress(InferenceApi.fetchRhymes, word, context, {
      ...options,
      random: true,
    }),
  ]);

  if (result) {
    yield put(succeedRhymeLines(callId, result));
    yield addEvents(callId, Mode.RHYMES, true, [result]);
  } else {
    yield put(failRhymeLines(error));
  }
}

export function* fetchThesaurusAsync({
  payload,
}: FetchAction<ActionTypes.FETCH_THESAURUS, ThesaurusOptions>) {
  const { word, context, options } = payload;
  const callId = uuid();

  yield put(loadThesaurus(word));

  const { result, error } = yield supress(
    InferenceApi.fetchThesaurus,
    word,
    context,
    options
  );

  if (result) {
    yield put(succeedThesaurus(callId, result));
    yield addEvents(callId, Mode.THESAURUS, false, [result]);
  } else {
    yield put(failThesaurus(error));
  }
}

export function* clearAsync() {
  yield all([put(clearAuto()), put(clearRhymes()), put(clearThesaurus())]);
}

export function* saga() {
  yield all([
    takeLatest(ActionTypes.FETCH_AUTO, fetchAutoAsync),
    takeLatest(ActionTypes.FETCH_AUTO_WORDS, fetchAutoWordsAsync),
    takeLatest(ActionTypes.FETCH_AUTO_LINES, fetchAutoLinesAsync),
    takeLatest(ActionTypes.FETCH_RHYMES, fetchRhymesAsync),
    takeLatest(ActionTypes.FETCH_RHYME_LINES, fetchRhymeLinesAsync),
    takeLatest(ActionTypes.FETCH_THESAURUS, fetchThesaurusAsync),
    takeLatest(ActionTypes.CLEAR, clearAsync),
  ]);
}
