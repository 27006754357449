import { pick } from "lodash";
import { SessionEvents } from "../../../constants";
import { ActionType } from "../actionTypes";
import { buildReducer } from "../helpers";
import { AnalyticsData } from "../types";

type SessionEvent = typeof SessionEvents;

function createSimpleReducer(
  name: SessionEvent[keyof SessionEvent]
): AnalyticsData.ReducerFn<AnalyticsData.State["events"]> {
  return (state, { timestamp, ...payload }) => [
    ...state,
    { name, payload, timestamp },
  ];
}

export const reduceEvents = buildReducer<AnalyticsData.State["events"]>({
  [ActionType.SELECTED_SUGGESTION]: (
    state,
    { suggestion, selectionType, lyricsId, timestamp }
  ) => {
    const name = SessionEvents.SUGGESTION_SELECTED;
    const payload = {
      lyricsId,
      selectionType,
      ...pick(
        suggestion,
        "callId",
        "requestId",
        "requestType",
        "mode",
        "type",
        "text",
        "index"
      ),
    };
    return [...state, { name, payload, timestamp }];
  },

  [ActionType.CHANGED_MODE]: (
    state,
    { lyricsId, current, previous, timestamp }
  ) => {
    const name = SessionEvents.MODE_SELECTED;
    const payload = { lyricsId, current, previous };
    return [...state, { name, payload, timestamp }];
  },

  [ActionType.REQUESTED_SUGGESTIONS]: (
    state,
    { callId, mode, response, lyricsId, timestamp, loadedMore }
  ) => {
    const name = SessionEvents.SUGGESTION_REQUEST;
    const payload = {
      lyricsId,
      callId,
      requestId: response?.requestId,
      requestType: response?.requestType,
      mode,
      input: response?.params,
      output: response?.data,
      config: {
        bertModel: response?.headers["x-bert-model"],
        gptModel: response?.headers["x-gpt-model"],
        release: response?.headers["x-release"],
        expTag: response?.headers["x-exp-tag"],
      },
      loadedMore,
    };
    return [...state, { name, payload, timestamp }];
  },

  [ActionType.OPENED_LYRICS]: (
    state,
    { song, accessMode, lyricsId, timestamp }
  ) => {
    const name = SessionEvents.OPENED_LYRICS;
    const payload = {
      lyricsId,
      title: song?.title,
      content: song?.content,
      accessMode,
    };
    return [...state, { name, payload, timestamp }];
  },

  [ActionType.CLOSED_LYRICS]: (
    state,
    { song, accessMode, lyricsId, timestamp }
  ) => {
    const name = SessionEvents.CLOSED_LYRICS;
    const payload = {
      lyricsId,
      title: song?.title,
      content: song?.content,
      accessMode,
    };
    return [...state, { name, payload, timestamp }];
  },

  [ActionType.HID_ASSISTANT]: createSimpleReducer(SessionEvents.HID_ASSISTANT),

  [ActionType.SHOWED_ASSISTANT]: createSimpleReducer(
    SessionEvents.SHOWED_ASSISTANT
  ),

  [ActionType.CLICKED_LOCKED_SUGGESTION]: (
    state,
    { lyricsId, suggestion, timestamp }
  ) => {
    const name = SessionEvents.CLICKED_LOCKED_SUGGESTION;
    const payload = {
      lyricsId,
      ...pick(
        suggestion,
        "callId",
        "requestId",
        "requestType",
        "mode",
        "type",
        "index"
      ),
    };
    return [...state, { name, payload, timestamp }];
  },

  [ActionType.CLICKED_LOCKED_METRIC]: createSimpleReducer(
    SessionEvents.CLICKED_LOCKED_METRIC
  ),

  [ActionType.CLICKED_LOCKED_LOAD_MORE]: createSimpleReducer(
    SessionEvents.CLICKED_LOCKED_LOAD_MORE
  ),

  [ActionType.RELOADED_SUGGESTIONS]: (
    state,
    { lyricsId, shortcut, timestamp }
  ) => {
    const name = SessionEvents.RELOADED_SUGGESTIONS;
    const payload = { lyricsId, shortcut };
    return [...state, { name, payload, timestamp }];
  },

  [ActionType.CLICKED_UPGRADE]: createSimpleReducer(
    SessionEvents.CLICKED_UPGRADE
  ),

  [ActionType.CHANGED_SETTINGS]: (state, { lyricsId, settings, timestamp }) => {
    const name = SessionEvents.CHANGED_SETTINGS;
    const payload = { lyricsId, settings };
    return [...state, { name, payload, timestamp }];
  },

  [ActionType.CHANGED_SONG_MOOD]: createSimpleReducer(
    SessionEvents.CHANGED_SONG_MOOD
  ),

  [ActionType.FLUSH]: (state, payload) => state.slice(payload.eventsLength),
});
