import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
} from "react";
import { useState } from "react";
import { ParentProps } from "../types/props";
import { FeatureFlags } from "../utils/feature-flags-utils";
import { useParentArgsContext } from "./parent-args-context";

type TestGroup = "disabled" | "control" | "a";

export type Experiment = {
  type: "off" | "beta" | "ab";
  tag?: string;
  features?: unknown;
  beta: TestGroup;
  group: TestGroup;
  raw: {
    beta: TestGroup;
    group: TestGroup;
  };
};

type ExperimentData = Record<string, unknown>;

type ContextValue<T extends ExperimentData = ExperimentData> = [
  Experiment,
  {
    data: T;
    setData: Dispatch<SetStateAction<T>>;
  }
];

const ExperimentsContext = createContext<ContextValue>([
  {
    type: "off",
    features: { mood: false, syllable: false },
    beta: "disabled",
    group: "disabled",
    raw: { beta: "disabled", group: "disabled" },
  },
  { data: { userId: null }, setData: () => undefined },
]);

export function useExperimentsContext<T extends ExperimentData>() {
  return useContext(ExperimentsContext) as ContextValue<T>;
}

/**
 * Don't delete this function if it's not been used. It is supposed to be used when there is a
 * front end test.
 */
// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
function checkFrontEndFeatureIsEnabled(
  key: string,
  featureFlags: FeatureFlags
) {
  const { betaTest, abTest, testFeature } = featureFlags;

  const isEnabled = testFeature?.key === key;
  const isBetaTest = testFeature?.type === "beta" && betaTest === "a";
  const isAbTest = testFeature?.type === "ab" && abTest === "a";

  return isEnabled && (isBetaTest || isAbTest);
}

export function ExperimentsContextProvider({ children }: ParentProps) {
  const { userId, featureFlags } = useParentArgsContext();
  const [data, setData] = useState<ExperimentData>({});

  const experiment: Experiment = useMemo(() => {
    const { betaTest, abTest, testFeature } = featureFlags;

    const isBetaTest = testFeature?.type === "beta" && betaTest !== "disabled";
    const isAbTest = testFeature?.type === "ab" && abTest !== "disabled";

    const control = {
      beta: (isBetaTest && betaTest) || ("disabled" as const),
      group: (isAbTest && abTest) || ("disabled" as const),
      raw: { beta: betaTest || "disabled", group: abTest || "disabled" },
    };

    return userId && (isBetaTest || isAbTest)
      ? { ...testFeature, ...control }
      : { type: "off" as const, ...control };
  }, [userId, featureFlags]);

  return (
    <ExperimentsContext.Provider value={[experiment, { data, setData }]}>
      {children}
    </ExperimentsContext.Provider>
  );
}
