import {
  CommonInferenceResult,
  RhymesInferenceResult,
  ThesaurusInferenceResult,
} from "../../api/inference";
import { ActionTypes } from "./action-types";

export function loadAutoWords(cleared?: boolean) {
  return { type: ActionTypes.FETCH_AUTO_WORDS_LOADING, payload: { cleared } };
}

export function succeedAutoWords(
  callId: string,
  result: CommonInferenceResult<string>
) {
  return {
    type: ActionTypes.FETCH_AUTO_WORDS_SUCCEEDED,
    payload: { callId, result },
  };
}

export function failAutoWords(error: Error) {
  return { type: ActionTypes.FETCH_AUTO_WORDS_FAILED, payload: error };
}

export function loadAutoLines(cleared?: boolean) {
  return { type: ActionTypes.FETCH_AUTO_LINES_LOADING, payload: { cleared } };
}

export function succeedAutoLines(
  callId: string,
  result: CommonInferenceResult<string>
) {
  return {
    type: ActionTypes.FETCH_AUTO_LINES_SUCCEEDED,
    payload: { callId, result },
  };
}

export function failAutoLines(error: Error) {
  return { type: ActionTypes.FETCH_AUTO_LINES_FAILED, payload: error };
}

export function loadRhymes(word: string) {
  return { type: ActionTypes.FETCH_RHYMES_LOADING, payload: { word } };
}

export function succeedRhymes(
  callId: string,
  rhymeWords: CommonInferenceResult<string>,
  rhymes: RhymesInferenceResult
) {
  return {
    type: ActionTypes.FETCH_RHYMES_SUCCEEDED,
    payload: { callId, rhymeWords, rhymes },
  };
}

export function failRhymes(error: Error) {
  return { type: ActionTypes.FETCH_RHYMES_FAILED, payload: error };
}

export function loadRhymeLines() {
  return { type: ActionTypes.FETCH_RHYME_LINES_LOADING };
}

export function succeedRhymeLines(
  callId: string,
  rhymes: RhymesInferenceResult
) {
  return {
    type: ActionTypes.FETCH_RHYME_LINES_SUCCEEDED,
    payload: { callId, rhymes },
  };
}

export function failRhymeLines(error: Error) {
  return { type: ActionTypes.FETCH_RHYME_LINES_FAILED, payload: error };
}

export function loadThesaurus(word: string) {
  return { type: ActionTypes.FETCH_THESAURUS_LOADING, payload: { word } };
}

export function succeedThesaurus(
  callId: string,
  result: ThesaurusInferenceResult
) {
  return {
    type: ActionTypes.FETCH_THESAURUS_SUCCEEDED,
    payload: { callId, result },
  };
}

export function failThesaurus(error: Error) {
  return { type: ActionTypes.FETCH_THESAURUS_FAILED, payload: error };
}
