import { createReducer } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { cloneDeep, uniqBy } from "lodash";
import { makeAddCaseFn } from "../../../utils/redux-utils";
import { ActionTypes } from "../action-types";
import { convertRhymesToLists, convertRhymeWordsToList } from "../helper";
import {
  ClearPayload,
  FirstRhymesPayload,
  LoadingRhymesPayload,
  NextRhymesPayload,
  RhymesState,
} from "../types";

export const INITIAL_STATE: RhymesState = {
  remote: {
    rhymeWords: {
      status: "idle",
      error: undefined,
    },
    rhymes: {
      status: "idle",
      error: undefined,
    },
  },
  local: {
    words: {
      items: [],
      hasMore: true,
      loadedMore: false,
    },
    lines: {
      items: [],
      hasMore: true,
      loadedMore: false,
    },
  },
  allWords: [],
  // TODO Check if storing this is really needed
  word: "",
  loadedOnce: false,
  cleared: true,
};

export const rhymesReducer = createReducer(INITIAL_STATE, (builder) => {
  const addCase = makeAddCaseFn(builder);

  addCase<LoadingRhymesPayload>(
    ActionTypes.FETCH_RHYMES_LOADING,
    (state, { payload }) => {
      state.remote.rhymeWords.status = "loading";
      state.remote.rhymeWords.error = undefined;
      state.remote.rhymes.status = "loading";
      state.remote.rhymes.error = undefined;
      state.local = { ...INITIAL_STATE.local };
      state.word = payload.word;
      state.loadedOnce = true;
      state.cleared = true;
    }
  );

  addCase<FirstRhymesPayload>(
    ActionTypes.FETCH_RHYMES_SUCCEEDED,
    (state, { payload }) => {
      const rhymeWords = convertRhymeWordsToList(payload);
      const rhymes = convertRhymesToLists(payload);
      const remainingWords = uniqBy([...rhymes.words, ...rhymeWords], "text");

      state.remote.rhymeWords.status = "succeeded";
      state.remote.rhymes.status = "succeeded";
      // TODO Extract constant for 20
      state.local.words.items = remainingWords.slice(0, 20);
      state.local.words.hasMore = remainingWords.length > 20;
      state.local.lines.items = rhymes.lines;
      state.local.lines.hasMore = rhymes.lines.length > 0;
      state.allWords = remainingWords;
    }
  );

  addCase<AxiosError>(ActionTypes.FETCH_RHYMES_FAILED, (state, { payload }) => {
    state.remote.rhymeWords.status = "failed";
    state.remote.rhymeWords.error = payload;
    state.remote.rhymes.status = "failed";
    state.remote.rhymes.error = payload;
  });

  addCase(ActionTypes.FETCH_RHYME_WORDS, (state) => {
    // TODO Extract constant for 10
    const size = state.local.words.items.length + 10;

    state.local.words.items = state.allWords.slice(0, size);
    state.local.words.hasMore = state.allWords.length > size;
    state.local.words.loadedMore = true;
  });

  addCase(ActionTypes.FETCH_RHYME_LINES_LOADING, (state) => {
    state.remote.rhymes.status = "loading";
    state.remote.rhymes.error = undefined;
    state.cleared = false;
  });

  addCase<NextRhymesPayload>(
    ActionTypes.FETCH_RHYME_LINES_SUCCEEDED,
    (state, { payload }) => {
      const rhymes = convertRhymesToLists(payload);
      const lines = uniqBy(
        [...state.local.lines.items, ...rhymes.lines],
        "text"
      );
      const hasMore = lines.length > state.local.lines.items.length;

      state.remote.rhymes.status = "succeeded";
      state.local.lines.items = lines;
      state.local.lines.hasMore = hasMore;
      state.local.lines.loadedMore = true;
    }
  );

  addCase<AxiosError>(
    ActionTypes.FETCH_RHYME_LINES_FAILED,
    (state, { payload }) => {
      state.remote.rhymes.status = "failed";
      state.remote.rhymes.error = payload;
    }
  );

  addCase<ClearPayload | undefined>(
    ActionTypes.CLEAR_RHYMES,
    (state, { payload }) => {
      if (!payload?.keepStatus) {
        return INITIAL_STATE;
      }

      state.remote = cloneDeep(INITIAL_STATE.remote);
      state.local = cloneDeep(INITIAL_STATE.local);
      state.allWords = cloneDeep(INITIAL_STATE.allWords);
      state.word = INITIAL_STATE.word;
    }
  );
});
