import * as Sentry from "@sentry/nextjs";
import { ScopeContext } from "@sentry/types";
import { isUndefined } from "lodash";
import { negate } from "lodash/fp";
import { config } from "../config";

type LogOptions = Partial<Pick<ScopeContext, "level" | "extra">>;

export function log(...args: unknown[]) {
  if (config.env === "development" || config.env === "stage") {
    console.log(...args.filter(negate(isUndefined)));
  }
}

export function captureMessage(
  message: string,
  { level, extra }: LogOptions = {}
) {
  log(`[${level || "none"}]`, message, extra);
  Sentry.captureMessage(message, { level, extra });
}

export function captureException(
  error: unknown,
  { level, extra }: LogOptions = {}
) {
  log(`[${level || "none"}]`, error, extra);
  Sentry.captureException(error, { level, extra });
}
