import { AnalyticsData } from "../data/analytics/types";

/**
 * Available events.
 */
enum Events {
  SESSION_ACTIONS = "muse_session_actions",
  SESSION_EVENTS = "muse_session_events",
  PUBLIC_LYRICS_VIEW = "public_lyrics_view",
  PAYWALL_HIT = "paywall_hit",
  CLICKED_UNLOCK_PAYWALL = "clicked_unlock_paywall",
}

type Category = "engagement" | "growth";

/**
 * Emits an event.
 */
function emitEvent(
  name: string,
  payload: Record<string, unknown> = {},
  category: Category = "engagement"
) {
  const message = { trackEvent: { name, payload, category } };
  global.window?.top?.postMessage(message, "*");
}

/**
 * Emits an event of session actions.
 */
function emitSessionActions(sessionActions: AnalyticsData.ActionsPacket) {
  emitEvent(Events.SESSION_ACTIONS, sessionActions);
}

/**
 * Emits an event of package of session events.
 */
function emitSessionEvents(sessionEvents: AnalyticsData.EventsPacket) {
  emitEvent(Events.SESSION_EVENTS, sessionEvents);
}

/**
 * Emits an event of public lyrics view.
 */
function emitPublicLyricsView(lyricsId: string) {
  emitEvent(Events.PUBLIC_LYRICS_VIEW, { lyricsId });
}

/**
 * Emits an event of paywall hit.
 */
function emitPaywallHit() {
  emitEvent(Events.PAYWALL_HIT, { source: "paywall-LyricWriter" }, "growth");
}

/**
 * Emits an event of clicked unlock paywall.
 */
function emitClickedUnlockPaywall() {
  emitEvent(
    Events.CLICKED_UNLOCK_PAYWALL,
    { source: "paywall-LyricWriter" },
    "growth"
  );
}

export const Analytics = {
  emitEvent,
  emitSessionActions,
  emitSessionEvents,
  emitPublicLyricsView,
  emitPaywallHit,
  emitClickedUnlockPaywall,
  Events,
};
