export enum ActionTypes {
  FETCH_AUTO = "suggestions/auto/fetch",
  FETCH_AUTO_WORDS = "suggestions/auto/words/fetch",
  FETCH_AUTO_WORDS_LOADING = "suggestions/auto/words/loading",
  FETCH_AUTO_WORDS_SUCCEEDED = "suggestions/auto/words/succeeded",
  FETCH_AUTO_WORDS_FAILED = "suggestions/auto/words/failed",
  FETCH_AUTO_LINES = "suggestions/auto/lines/fetch",
  FETCH_AUTO_LINES_LOADING = "suggestions/auto/lines/loading",
  FETCH_AUTO_LINES_SUCCEEDED = "suggestions/auto/lines/succeeded",
  FETCH_AUTO_LINES_FAILED = "suggestions/auto/lines/failed",
  FETCH_RHYMES = "suggestions/rhymes/fetch",
  FETCH_RHYMES_LOADING = "suggestions/rhymes/loading",
  FETCH_RHYMES_SUCCEEDED = "suggestions/rhymes/succeeded",
  FETCH_RHYMES_FAILED = "suggestions/rhymes/failed",
  FETCH_RHYME_WORDS = "suggestions/rhymes/lines/words",
  FETCH_RHYME_LINES = "suggestions/rhymes/lines/fetch",
  FETCH_RHYME_LINES_LOADING = "suggestions/rhymes/lines/loading",
  FETCH_RHYME_LINES_SUCCEEDED = "suggestions/rhymes/lines/succeeded",
  FETCH_RHYME_LINES_FAILED = "suggestions/rhymes/lines/failed",
  FETCH_THESAURUS = "suggestions/thesaurus/fetching",
  FETCH_THESAURUS_LOADING = "suggestions/thesaurus/loading",
  FETCH_THESAURUS_SUCCEEDED = "suggestions/thesaurus/succeeded",
  FETCH_THESAURUS_FAILED = "suggestions/thesaurus/failed",
  CLEAR = "suggestions/clear",
  CLEAR_AUTO = "suggestions/clear/auto",
  CLEAR_RHYMES = "suggestions/clear/rhymes",
  CLEAR_THESAURUS = "suggestions/clear/thesaurus",
}
