import Constants from "../constants";

export function parseNumberEnvVar<T = number | undefined | null>(
  value: string | undefined,
  defaultValue: T
): number | T {
  if (typeof value !== "string") {
    return defaultValue;
  }

  const parsed = parseFloat(value);
  return isNaN(parsed) ? defaultValue : parsed;
}

export function parseBooleanEnvVar(
  value: string | undefined,
  defaultValue: boolean
): boolean {
  if (typeof value !== "string") {
    return defaultValue;
  }

  switch (value.toLowerCase()) {
    case "true":
    case "1":
      return true;

    case "false":
    case "0":
      return false;

    default:
      return defaultValue;
  }
}

const MOISES_AI_URL_REGEX = /^https:\/\/([\w-]+\.)*moises\.ai([/:?].*)?$/;

export function isValidMoisesAiUrl(url: string): boolean {
  return MOISES_AI_URL_REGEX.test(url);
}

export function ensureSongsLimitRedirectionUrl(
  url: string | undefined
): string {
  if (!url) {
    return Constants.SONGS_LIMIT_REDIRECTION_URL;
  }

  if (!isValidMoisesAiUrl(url)) {
    console.error("Songs limit redirection URL is invalid:", url);
    return Constants.SONGS_LIMIT_REDIRECTION_URL;
  }

  return url;
}
