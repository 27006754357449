import createPersistedState from "use-persisted-state";
import { useCreateInitialSong } from "../api/service";
import { useCallback, useEffect, useRef } from "react";
import Constants from "../constants";
import { extractStatus } from "../utils/hook-utils";
import { Status } from "../types/common";
import {
  DemoData,
  DemoSongProvider,
} from "../lib/song-providers/demo-song-provider";
import { useInDemo } from "./use-in-demo";
import { AnySongContent } from "../types/song";
import { ensureSlateContent } from "../utils/slate-utils";

export type SongRecovery = {
  hasData: boolean;
  status: Status;
  songId: string | null;
};

type HookReturnValue = [SongRecovery, () => void];

const keys = DemoSongProvider.DEFAULT_KEYS;
const useTitleState = createPersistedState<string | null>(keys.title);
const useContentState = createPersistedState<AnySongContent | null>(
  keys.content
);
const useDemoDataState = createPersistedState<DemoData | null>(keys.data);

/**
 * Hook that recovers the song stored in the local storage and creates it using the API. It
 * should happen only once, so the song is deleted from the storage as soon as it's read.
 *
 * Songs stored in the local storage comes from the first version of the app and also the demo.
 */
export function useRecoverLocallyStoredSong(): HookReturnValue {
  const [title = null, setTitle] = useTitleState();
  const [content = null, setContent] = useContentState();
  const [demoData = null, setDemoData] = useDemoDataState();
  const [initialSong, createInitialSong] = useCreateInitialSong();
  const status = extractStatus(initialSong);

  // Counts only on the first time to prevent running the recovery when the demo is been played in
  // another tab
  const hasData = useRef(Boolean(title || content)).current;

  // Shouldn't recover the locally stored song during the use of the demo because it would cause
  // the demo song to be cleared
  const inDemo = useInDemo();

  useEffect(() => {
    if (initialSong?.value) {
      dispatchEvent(new Event(Constants.CREATED_INITIAL_SONG_EVENT));
    }
  }, [initialSong?.value]);

  const recoverLocallyStoredSong = useCallback(() => {
    if (status === "idle" && !inDemo && hasData && (title || content)) {
      setTitle(null);
      setContent(null);
      setDemoData(null);

      createInitialSong({
        ...demoData,
        title: title || undefined,
        content: ensureSlateContent(content) || undefined,
      });
    }
  }, [title, content, demoData, status, inDemo]);

  return [
    {
      hasData,
      status: status,
      songId: initialSong.value?.id || null,
    },
    recoverLocallyStoredSong,
  ];
}
