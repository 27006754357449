import React, {
  createContext,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { runOnClient } from "../utils/next-utils";
import { uuid } from "../utils/crypto-utils";
import { SessionJsonStorage } from "../lib/session-json-storage";
import { noop } from "lodash";
import { AnalyticsData } from "../data/analytics/types";
import { flush } from "../data/analytics/actions";
import Constants from "../constants";
import { createAnalyticsState } from "../utils/analytics-utils";
import { useExperimentsContext } from "./experiments-context";
import { useStateRef } from "../hooks/use-state-ref";
import { useParentArgsContext } from "./parent-args-context";
import { StartUpTasks } from "../constants/start-up-tasks";

type AnalyticsContextProviderProps = {
  key?: string;
  children: ReactNode;
};

export type AnalyticsStorage = {
  userId?: string;
  sessionId?: string;
  lyricsId?: string;
  experimentTag?: string;
  analytics: AnalyticsData.State;
  task?: StartUpTasks.Any;
};

type AnalyticsContextValue = [
  { current: AnalyticsStorage },
  (state: Partial<AnalyticsStorage>) => void
];

const { STORAGE_KEY } = Constants;

const AnalyticsContext = createContext<AnalyticsContextValue>([
  {
    current: { analytics: createAnalyticsState() },
  },
  () => undefined,
]);

export function useAnalyticsContext() {
  return useContext(AnalyticsContext);
}

function createState(
  userId?: string,
  task?: StartUpTasks.Any
): AnalyticsStorage {
  return { userId, sessionId: uuid(), analytics: createAnalyticsState(), task };
}

// The source of truth for the analytics data is this static variable
const memory: MutableRefObject<AnalyticsStorage | undefined> = {
  current: undefined,
};

function useAnalyticsState(
  key: string,
  userId = "nouser",
  task?: StartUpTasks.Any,
  experimentTag?: string
): [
  MutableRefObject<AnalyticsStorage>,
  (state: Partial<AnalyticsStorage>) => void
] {
  return useStateRef(memory, () => {
    const state = runOnClient(() =>
      SessionJsonStorage.getItem<AnalyticsStorage>(key)
    );

    const isValid = state?.sessionId && state?.analytics;
    const isSame =
      state?.userId === userId &&
      state?.task === task &&
      state?.experimentTag === experimentTag;

    if (isValid && !isSame) {
      // If the user, task, or experiment changed, flush the analytics data - the data will be
      // erased further by creating a new state
      flush(noop)(
        state.sessionId!,
        Constants.CONTEXT_CHANGED,
        state.analytics,
        state.task
      );
    }

    return isValid && isSame ? state : createState(userId, task);
  });
}

/**
 * Context provider for the session storage.
 */
export function AnalyticsContextProvider({
  key = STORAGE_KEY,
  children,
}: AnalyticsContextProviderProps) {
  const { userId, task } = useParentArgsContext();
  const [experiment] = useExperimentsContext();
  // Load the analytics data from the session storage on start up
  const [state, setState] = useAnalyticsState(
    key,
    userId,
    task,
    experiment.tag
  );

  // Copy the analytics data to the session storage when it changes
  useEffect(
    () => SessionJsonStorage.setItem(key, state.current),
    [state.current]
  );

  return (
    <AnalyticsContext.Provider value={[state, setState]}>
      {children}
    </AnalyticsContext.Provider>
  );
}
