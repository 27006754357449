import { useRef } from "react";

/**
 * Hook that holds a boolean value that once it is set to true, doesn't change.
 */
export function useTrueOnce(value) {
  const ref = useRef(Boolean(value));
  ref.current ||= Boolean(value);
  return ref.current;
}
