export enum Mode {
  AUTO = "auto",
  RHYMES = "rhymes",
  THESAURUS = "thesaurus",
  BETA = "beta",
}

export type DataMode = Mode.AUTO | Mode.RHYMES | Mode.THESAURUS;

export type AssistantMode = Mode.RHYMES | Mode.THESAURUS | Mode.BETA;

export type InferenceMode = Mode.AUTO | Mode.RHYMES;

export function checkDataMode(mode: Mode): mode is DataMode {
  return mode === Mode.RHYMES || mode === Mode.THESAURUS;
}

export function checkInferenceMode(mode: Mode): mode is InferenceMode {
  return mode === Mode.AUTO || mode === Mode.RHYMES;
}
