import { AnySongContent, SongOptions } from "../../types/song";
import Constants, { DEFAULT_SONG_OPTIONS } from "../../constants";
import { SongProvider } from "./types";
import { isNil } from "lodash";
import { Mood } from "../../constants/moods";

export type DemoSongProviderKeys = {
  title: string;
  content: string;
  data: string;
};

export type DemoData = typeof DEFAULT_SONG_OPTIONS & {
  mood: Mood["value"];
  metric: number | null;
  createdAt?: string;
  updatedAt?: string;
};

export type ContentParser = (
  content: AnySongContent | null
) => AnySongContent | null;

export class DemoSongProvider implements SongProvider {
  static DEFAULT_KEYS: DemoSongProviderKeys = {
    title: "title",
    content: "content",
    data: "demo_data",
  };

  constructor(
    private readonly keys = DemoSongProvider.DEFAULT_KEYS,
    private readonly contentParser?: ContentParser
  ) {}

  private getTitle(): string {
    const rawTitle = localStorage.getItem(this.keys.title);
    const parsedTitle = isNil(rawTitle) ? null : JSON.parse(rawTitle);
    return parsedTitle ?? Constants.DEFAULT_SONG_TITLE;
  }

  private getContent(): AnySongContent | null {
    const contentStr = localStorage.getItem(this.keys.content) || null;
    const content = contentStr
      ? (JSON.parse(contentStr) as AnySongContent)
      : null;
    return this.contentParser ? this.contentParser(content) : content;
  }

  private getData(): DemoData {
    const rawOptions = localStorage.getItem(this.keys.data);
    return (rawOptions && JSON.parse(rawOptions)) || DEFAULT_SONG_OPTIONS;
  }

  get reference() {
    const title = this.getTitle();
    const content = this.getContent();
    const data = this.getData();

    return {
      ...data,
      id: "demo",
      title,
      content,
      language: null,
      public: false,
      createdAt: data.createdAt ? new Date(data.createdAt) : new Date(),
      updatedAt: data.updatedAt ? new Date(data.updatedAt) : new Date(),
    };
  }

  async fetch() {
    return this.reference;
  }

  async updateTitle(title: string) {
    localStorage.setItem(this.keys.title, JSON.stringify(title));

    return this.updateOptions({});
  }

  async updateContent(content: unknown) {
    localStorage.setItem(this.keys.content, JSON.stringify(content));

    return this.updateOptions({});
  }

  async updateOptions(newOptions: Partial<SongOptions>) {
    const currentOptions = this.getData();
    const updatedAt = new Date();

    localStorage.setItem(
      this.keys.data,
      JSON.stringify({
        ...currentOptions,
        ...newOptions,
        updatedAt: updatedAt.toISOString(),
      })
    );

    return { language: null, updatedAt };
  }
}
