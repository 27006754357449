import { createReducer } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { makeAddCaseFn } from "../../../utils/redux-utils";
import { ActionTypes } from "../action-types";
import {
  convertThesaurusAntonymsToList,
  convertThesaurusDefinitionsToList,
  convertThesaurusSynonymsToList,
} from "../helper";
import {
  ClearPayload,
  LoadingThesaurusPayload,
  SuccessThesaurusPayload,
  ThesaurusState,
} from "../types";

export const INITIAL_STATE: ThesaurusState = {
  remote: {
    thesaurus: {
      status: "idle",
      error: undefined,
    },
  },
  local: {
    definitions: {
      items: [],
      hasMore: false,
      loadedMore: false,
    },
    synonyms: {
      items: [],
      hasMore: false,
      loadedMore: false,
    },
    antonyms: {
      items: [],
      hasMore: false,
      loadedMore: false,
    },
  },
  word: "",
  loadedOnce: false,
  cleared: true,
};

export const thesaurusReducer = createReducer(INITIAL_STATE, (builder) => {
  const addCase = makeAddCaseFn(builder);

  addCase<LoadingThesaurusPayload>(
    ActionTypes.FETCH_THESAURUS_LOADING,
    (state, { payload }) => {
      state.remote.thesaurus.status = "loading";
      state.remote.thesaurus.error = undefined;
      state.local = INITIAL_STATE.local;
      state.word = payload.word;
      state.loadedOnce = true;
    }
  );

  addCase<SuccessThesaurusPayload>(
    ActionTypes.FETCH_THESAURUS_SUCCEEDED,
    (state, { payload }) => {
      const definitions = convertThesaurusDefinitionsToList(payload);
      const synonyms = convertThesaurusSynonymsToList(payload);
      const antonyms = convertThesaurusAntonymsToList(payload);

      state.remote.thesaurus.status = "succeeded";
      state.local.definitions.items = definitions;
      state.local.synonyms.items = synonyms;
      state.local.antonyms.items = antonyms;
    }
  );

  addCase<AxiosError>(
    ActionTypes.FETCH_THESAURUS_FAILED,
    (state, { payload }) => {
      state.remote.thesaurus.status = "failed";
      state.remote.thesaurus.error = payload;
    }
  );

  addCase<ClearPayload | undefined>(
    ActionTypes.CLEAR_THESAURUS,
    (state, { payload }) => {
      if (!payload?.keepStatus) {
        return INITIAL_STATE;
      }

      state.remote = { ...INITIAL_STATE.remote };
      state.local = { ...INITIAL_STATE.local };
      state.word = INITIAL_STATE.word;
    }
  );
});
