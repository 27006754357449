import { useAsyncFn } from "react-use";
import { useMemo } from "react";
import { useTrueOnce } from "./use-true-once";
import { uuid } from "../utils/crypto-utils";

/**
 * @template T
 * @param {import("..").FetchApiHandler<T>} fn
 * @returns {import("..").FetchApiHookReturnValue<T>}
 */
export function useFetchApi(fn) {
  const [defaultState, fetch] = useAsyncFn(async (...args) => {
    const { params = {}, value, config = {} } = await fn(...args);
    return [uuid(), params, value, config];
  });

  const loadedOnce = useTrueOnce(defaultState.loading);

  const state = useMemo(() => {
    const [id = null, params = null, value = null, config = null] =
      defaultState.value || [];

    return {
      loading: defaultState.loading || false,
      error: defaultState.error || null,
      id,
      params,
      value,
      config,
      didOnce: loadedOnce,
    };
  }, [defaultState]);

  return [state, fetch];
}
